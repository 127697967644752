
import React from 'react';
import { Link } from 'react-router-dom';

import Image1 from '../module1/WebAppModule1_Screen3.png'
import Image2 from '../module1/WebAppModule1_Screen3Info.png'

const GuideLine = () => {
    return (
        <div class="rounded-lg grid grid-cols-1 md:grid-cols-3 grid-rows-1 mx-0 md:mx-24 bg-white shadow-2xl">
  <div class="col-span-2">
      <div className="bg-blue-400 w-full h-14 text-center align-middle py-3 rounded-lg">
          Instructions
          
      </div>
      <img src={Image1} alt='Instructions'/>
  </div>
  
    <div className="bg-blue-400 rounded-lg">
        <div className="bg-white text-center text-3xl text-blue-500 h-14 ">
            Guidelines
        </div>
        <div className="text-white mx-9 pt-4 ">
            <ul>
                <li>● Please scan a video under ambient lighting for a few seconds.</li>
                <li>● Please remove accessories from your face and keep your facial and eye features visible to the front camera.</li>
                <li>● Please remain still during the scanning</li>
            </ul>
            
        </div>
        <div className="mx-9 pt-4">
            <img src={Image2}  alt='Instructions'/>
            
        </div>
        <div className=" mx-10 pt-4">
                    <Link to="/scan" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Next</Link>
                    
            
        </div>
                
    </div>
  
</div>
      );
}
 
export default GuideLine;