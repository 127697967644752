import React from 'react';

const ScanV4 = () => {
    return (<>
        Hello world
        <h1>Docsun Real Time Detection Demo</h1>
  <br />
  <div id="vital_history" > 
    <h4> Diagnosis :  <input type="text" id="diagnosis" /></h4>
    <h4> Pulse Rate : <input type="text" id="heart_rate" /> beats per minute</h4>
    <h4> SPO2 Level : <input type="text" id="spo2_rate" /> - % </h4>
    <h4> Breathing Rate : <input type="text" id="br_rate" /> -  breaths per minute</h4>
    <h4> Blood Pressure : <input type="text" id="syst_rate" /> - / <input type="text" id="diast_rate" /> - mmHg</h4>
    <h4> Blood Sugar : <input type="text" id="bs_rate" /> mmol/L </h4>
  </div>
  <br />
  
   <div>
    <video hidden id="webcam" width="640" height="480" autoplay></video>
    <canvas id="canvas" width="640" height="480"></canvas>
    <button id="scan">Start Scanning</button>
    <button id="stop" disabled>Stop Scanning</button>
  </div>
  

    </>);
}
 
export default ScanV4;