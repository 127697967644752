import React from 'react';
// import { Chart } from "react-google-charts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Example from '../CustomChart';
import V2 from '../../module1/TabBar-Wellness@2x.png'
import Health from '../../module1/TabBar-Healthiness@2x.png'
import WellnessIcon from '../../module1/TabBar-Wellness_1@2x.png'
import { Link } from 'react-router-dom';
import DashPie from '../charts/DashPie';
import MyApp from '../Calender';
import Normal from '../../module1/WebAppModule1_Screen5Green.png'
import BodyMassIcon from '../module3/Body Mass Index (BMI)@2x.png'
import TimerIcon from '../module3/Asset 95@2x.png'
import Timer2Icon from '../module3/TimerClock@2x.png'
import ExerciseIcon from '../module3/Exercise@2x.png'
import WaterIcon from '../module3/Water@2x.png'
import FoodIcon from '../module3/Food@2x.png'
import SleepIcon from '../module3/Sleep@2x.png'
import MenstralIcon from '../module3/Menstrual Cycle@2x.png'
import Gauge from '../charts/Gauge';
import BackIcon from '../../module1/SignInUserToggleButton.png'
import ScanButtonIcon from '../../module1/SignInUserScanButton.png'
import MyLineChart from '../charts/LineChart';
// import BackIcon from '../module1/SignInUserToggleButton.png'



const Sleep = () => {
  return (

    <>
    
    <div className="flex  md:flex-row flex-col">
      {/* <Example /> */}
      <div class="flex flex-row md:flex-col mr-1 md:w-60 w-auto justify-between border-r-2 ">

{/* 1 */}
<div className="flex flex-row md:flex-col mr-1 md:w-60 w-auto">
    {/* menus */}
    <div className="h-16 md:mx-0 mx-2">
    <div className="my-5 flex">

        <div className="ml-4">
            <img src={V2} className="w-7" />
        </div>
        <div className="ml-2">
           <Link to='/scan'> Vital Signs</Link>

        </div>
        
        
        
        </div>  
</div>
<div className=" h-16 md:mx-0 mx-2">
    <div className="my-5 flex">
        <div className="ml-4">
            <img src={Health} className="w-7" />
        </div>
        
        <div className="ml-2">
           <Link to='/healthness'> Healthness</Link>

        </div>
    </div>

</div>
<Link to='/wellness'>
<div className=" h-16 md:mx-0 mx-2 border-y-2 bg-blue-200 border-r-4 border-r-blue-500">
    <div className="my-5 flex">
        
        <div className="ml-4">
            <img src={WellnessIcon} className="w-7" />
        </div>
        
        <span className="ml-2 ">
            Wellness 

            </span>
            
    </div>
   

</div>
</Link>
    
</div>
<div className="md:visible invisible">
    
    <div className="flex flex-col">
        <div>
            <img src={ScanButtonIcon} />
        </div>
        <div className=" bg-gray-400 mr-7 h-20">
            <span className=" my-8 mx-8 pt-3 mt-8"> Version: 2.3.1 </span>
        </div>
        <div className="h-20">
            <img src={BackIcon} className=" mx-12 my-5" />
        </div>


    </div>

</div>




</div>

            
      
        <div className="flex flex-col w-full">
            <div className='flex flex-row justify-between bg-gradient-to-b from-blue-500 to-white py-3'>
                <div className='flex flex-row'>
                    <div>
                        <Link to='/wellness'>
                        <img src={BackIcon} className='mr-6 h-10' />

                        </Link>
                     
                    </div>
                    <div className="text-extrabold text-2xl">
                    Sleep
                    </div>

                
                </div>
                {/* <div className="mr-8 ">
                    <Link to='/bmi'>
                    <button className='btn btn-success h-9 text-white text-2xl text-extrabold bg-green-500 hover:bg-green-700 w-64'> Calculate BMI</button>
                    </Link>
                    

                </div> */}

            </div>
            
          <div className="flex bg-white flex-col md:flex-row w-full">
              {/* Here */}
              <div className="flex md:w-1/2 w-full flex-col ml-3 mt-2">
                    <div className='flex flex-col'>
                        <div className='text-blue-500 text-3xl font-bold'>
                        If you go to sleep now
                        </div>
                        <div>
                        You should wake up at:
                        </div>
                        <div className='flex flex-row justify-between mx-3 py-1 py-4'>
                            <div className='text-3xl font-black'>
                                16:51
                            </div>
                            <div className='pt-2'>
                                Nap
                            </div>
                            <div>
                                <img src={TimerIcon} className="h-12" />
 
                            </div>

                        </div>
                        <div className='flex flex-row justify-between mx-3 py-1 bg-blue-500 py-4 rounded-lg'>
                            <div className='text-3xl font-black'>
                                16:51
                            </div>
                            <div className='pt-2'>
                                1 Cycle
                            </div>
                            <div>
                                <img src={Timer2Icon} className=" h-12" />
 
                            </div>

                        </div>
                        <div className='flex flex-row justify-between mx-3 py-1 bg-blue-500 py-4 rounded-lg my-2'>
                            <div className='text-3xl font-black'>
                                16:51
                            </div>
                            <div className='pt-2'>
                                1 Cycle
                            </div>
                            <div>
                                <img src={Timer2Icon} className=" h-12" />
 
                            </div>

                        </div>
                        <div className='flex flex-row justify-between mx-3 py-1 bg-blue-500 py-4 rounded-lg my-1'>
                            <div className='text-3xl font-black'>
                                16:51
                            </div>
                            <div className='pt-2'>
                                1 Cycle
                            </div>
                            <div>
                                <img src={Timer2Icon} className="h-12" />
 
                            </div>

                        </div>
                        <div className='flex flex-row justify-between mx-3 py-1 bg-blue-500 py-4 rounded-lg my-1'>
                            <div className='text-3xl font-black'>
                                16:51
                            </div>
                            <div className='pt-2'>
                                1 Cycle
                            </div>
                            <div>
                                <img src={Timer2Icon} className="h-12" />
 
                            </div>

                        </div>
                        <div className='flex flex-row justify-between mx-3 py-1 bg-blue-500 py-4 rounded-lg my-1'>
                            <div className='text-3xl font-black'>
                                16:51
                            </div>
                            <div className='pt-2'>
                                1 Cycle
                            </div>
                            <div>
                                <img src={Timer2Icon} className="h-12" />
 
                            </div>

                        </div>

                    </div>

                </div>
              
            
                <div className="flex md:w-1/2 w-full flex-col">
                    <div className='text-2xl font-blank font-extrabold'>
                        Daily

                    </div>
                    <div className='rounded-lg bg-blue-400 '>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col ml-4'>
                                <div className='text-white text-extrabold text-4xl'>
                                Hourly / Daily
                                </div>
                                <div className='text-white text-2xl'>
                                Hourly Average: 99.67%

                                </div>
                                
                            </div>
                            <div className='flex flex-col ml-4'>
                                <div className='text-white text-extrabold text-4xl'>
                                99.69%
                                </div>
                                <div className='text-white text-extrabold text-2xl'>
                                Yesterday, 5:58pm

                                </div>
                                
                            </div>
                            

                        </div>
                        <div className='ml-3 my-5'>
                            100
                            <hr />
                        </div>
                        <div>
                            <MyLineChart />
                        </div>
                        

                    </div>
                    <div className='text-2xl font-blank font-extrabold'>
                        Monthly

                    </div>
                    <div className='rounded-lg bg-teal-500 '>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-col ml-4'>
                                <div className='text-white text-extrabold text-4xl'>
                                Weekly/Monthly
                                </div>
                                <div className='text-white text-2xl'>
                                Hourly Average: 99.67%

                                </div>
                                
                            </div>
                            <div className='flex flex-col ml-4'>
                                <div className='text-white text-extrabold text-4xl'>
                                99.69%
                                </div>
                                <div className='text-white text-extrabold text-2xl'>
                                Yesterday, 5:58pm

                                </div>
                                
                            </div>
                            

                        </div>
                        <div className='ml-3 my-5'>
                            100
                            <hr />
                        </div>
                        <div>
                            <MyLineChart />
                        </div>
                        

                    </div>
                </div>
                
                
          
          </div>
          
          
        </div>
        



       

            
      
      
      </div>

      </>
      
      );
}
 
export default Sleep;