import React from "react";
import { Chart } from "react-google-charts";

// export const data = [
//   ["Language", "Speakers (in millions)"],
//   ["Sp0 2 Levels"+blood_pressure_range, 14.2],
//   ["Blood Sugar", 14.2],
//   ["Blood pressure", 14.2],
//   ["Breathing Rate", 18.2],
//   ["Heart Rate", 10.2],
//   ["Temperature", 14.2],
//   ["Eye Redness", 14.2],
// ];

export const options = {
  legend: "none",
  pieSliceText: "label",
  title: "Weekly report",
  pieStartAngle: 20,
};

export default function DashPie(props) {
  console.log(props.vitals)
  console.log('props')
  // alert('props')
   const data = [
  ["Vital Signs", "Vital Signs"],
  ["Sp02 "+props.vitals.blood_pressure_range, 14.2],
  ["Blood Sugar"+props.vitals.blood_sugar_range, 14.2],
  ["BP"+props.vitals.blood_pressure_range, 14.2],
  ["Breathing Rate"+props.vitals.respiration_rate_range, 18.2],
  ["Heart Rate"+props.vitals.heart_rate_range, 10.2],
  ["Temperature"+props.vitals.temperature, 14.2],
  ["Eye"+props.vitals.eye_coloration, 14.2],
];
  return (
    <div className="text-xs">
      
    <Chart
      className="text-sm"
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"400px"}
      />
      </div>
  );
}
