import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DailyStats from './DailyStats';

export default function MyApp() {
    const [value, onChange] = useState(new Date());
    const [selecteddate, setSelectedDate] = useState('');
    const [datechage, setDateChange] = useState(0);

    const configureDate = (value, event) => {
        // console.log(value)
        // console.log(event)
        // alert(value)
      setDateChange(1)
        var newdt = moment(value).format()
    //    alert( moment(value).format())
        setSelectedDate(newdt)
        
    }
    
    // useEffect(() => {
    //     console.log(value)
    //     console.log('value')
        
    // })

  return (
      <div>
      {selecteddate}

      {datechage == 1 ? <>
        <DailyStats dt={datechage} />
      
      </> : <>
      
      </>}


      
      <Calendar onChange={onChange} value={value} onClickDay={(value, event) => configureDate(value, event)} />
    </div>
  );
}