import React from 'react';
// import { Chart } from "react-google-charts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Example from '../CustomChart';
import V2 from '../../module1/TabBar-Wellness@2x.png'
import Health from '../../module1/TabBar-Healthiness@2x.png'
import WellnessIcon from '../../module1/TabBar-Wellness_1@2x.png'
import { Link } from 'react-router-dom';
import DashPie from '../charts/DashPie';
import MyApp from '../Calender';
import Normal from '../../module1/WebAppModule1_Screen5Green.png'
import BodyMassIcon from '../module3/Body Mass Index (BMI)@2x.png'
import ExerciseIcon from '../module3/Exercise@2x.png'
import WaterIcon from '../module3/Water@2x.png'
import FoodIcon from '../module3/Food@2x.png'
import SleepIcon from '../module3/Sleep@2x.png'
import MenstralIcon from '../module3/Menstrual Cycle@2x.png'
import Gauge from '../charts/Gauge';
import BackIcon from '../../module1/SignInUserToggleButton.png'
import ScanButtonIcon from '../../module1/SignInUserScanButton.png'
import MyLineChart from '../charts/LineChart';
import EditIcon from '../../components/module3/Icon-2 (2)@2x.png'
import ClockIcon from '../../components/module3/Asset 95@2x.png'
import GlassIcon from '../../components/module3/Icon-2@2x.png'
import DeleteIcon from '../../components/module3/Icon-4 (3)@2x.png'
import FlevouredIcon from '../../components/module3/Icon-3@2x.png'
import CoffeeIcon from '../../components/module3/Icon-4@2x.png'
import JuiceIcon from '../../components/module3/Icon-5@2x.png'
import Thermometer from 'react-thermometer-chart';
// import BackIcon from '../module1/SignInUserToggleButton.png'



const Water = () => {
  return (

    <div className="h-screen">
    
    <div className="flex  md:flex-row flex-col  h-screen">
      {/* <Example /> */}
      <div class="flex flex-row md:flex-col mr-1 md:w-60 w-full md:justify-between border-r-2 ">

{/* 1 */}
<div className="flex flex-row md:flex-col mr-1 md:w-60 w-auto">
    {/* menus */}
    <div className="h-16 md:mx-0 mx-2">
    <div className="md:my-5 flex">

        <div className="ml-4">
            <img src={V2} className="w-7" />
        </div>
        <div className="ml-2">
           <Link to='/scan'> Vital Signs</Link>

        </div>
        
        
        
        </div>  
</div>
<div className=" h-16 md:mx-0 mx-2">
    <div className="my-5 flex">
        <div className="ml-4">
            <img src={Health} className="w-7" />
        </div>
        
        <div className="ml-2">
           <Link to='/healthness'> Healthness</Link>

        </div>
    </div>

</div>
<Link to='/wellness'>
<div className=" h-16 md:mx-0 mx-2 border-y-2 bg-blue-200 border-r-4 border-r-blue-500">
    <div className="my-5 flex">
        
        <div className="ml-4">
            <img src={WellnessIcon} className="w-7" />
        </div>
        
        <span className="ml-2 ">
            Wellness 

            </span>
            
    </div>
   

</div>
</Link>
    
</div>
<div className="md:visible invisible">
    
    <div className="flex flex-col">
        <div>
            <img src={ScanButtonIcon} />
        </div>
        <div className=" bg-gray-400 mr-7 h-20">
            <span className=" my-8 mx-8 pt-3 mt-8"> Version: 2.3.1 </span>
        </div>
        <div className="h-20">
            <img src={BackIcon} className=" mx-12 my-5" />
        </div>


    </div>

</div>




</div>

            
      
        <div className="flex flex-col w-full">
            <div className='flex flex-row justify-between bg-gradient-to-b from-blue-500 to-white py-3'>
                <div className='flex flex-row'>
                    <div>
                        <Link to='/wellness'>
                        <img src={BackIcon} className='mr-6 h-10' />

                        </Link>
                     
                    </div>
                    <div className="text-extrabold text-2xl">
                    Water Intake
                    </div>

                
                </div>
                <div className="mr-8 ">
                    <Link to='/wellness'>
                    <button className='btn btn-success h-9 text-white text-2xl text-extrabold bg-green-500 hover:bg-green-700 w-64'> Statistics</button>
                    </Link>
                    

                </div>

            </div>
            
          <div className="flex bg-white flex-col md:flex-row w-full">
                <div className="flex md:w-1/3 w-full flex-col">
                    
                    <div className="text-blue-500 border-2 rounded-lg flex flex-col justify-center pt-6 ">
                    <div class=" ml-40 md:ml-24  items-center radial-progress" style={{"--value":70, "--size":"12rem", "--thickness": "2rem;"}}>
                    <div className='flex flex-col text-black'>
                            <div>
                            Drink Target
                            </div>
                            <div className='font-black'>
                             <span className='text-blue-500'>1500</span>/5000ml
                            </div>

                        </div>
                    </div>
                    
                    <div className='flex flex-row space-x-2 mx-2'>
                        <div>
                        100ml
                        </div>
                        <div>
                        100ml
                        </div>
                        <div>
                        150ml
                        </div>
                        <div>
                        200ml
                        </div>
                        <div className='text-blue-500'>
                        250ml
                        </div>
                        <div>
                        300ml
                        </div>
                        <div>
                        350ml
                        </div>

                    </div>
                    <div className='flex flex-col mx-6'>
                        <div className='text-2xl font-bold'>
                        Today's Record
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className='flex flex-row space-x-5'>
                            <div>
                                <img src={ClockIcon} className=" h-10" />

                            </div>
                            <div className='pt-1'>
                                Next Reminder

                            </div>
                            </div>
                            <div className='flex flex-row space-x-2'>
                                <div>
                                08:00am
                                </div>
                                <div>
                                <i class="fas fa-edit"></i>
                                </div>
                            </div>

                        </div>
                        <div className='flex flex-row justify-between py-2 border-t-2'>
                            <div className='flex flex-row space-x-5 '>
                            <div>
                                <img src={GlassIcon} className=" h-8" />

                            </div>
                            <div className='pt-1'>
                            200ml

                            </div>
                            </div>
                            <div className='flex flex-row space-x-2'>
                                <div>
                                08:00am
                                </div>
                                <div>
                                <img src={DeleteIcon} className="h-5" />
                                </div>
                            </div>

                        </div>
                        <div className='flex flex-row justify-between py-2 border-t-2'>
                            <div className='flex flex-row space-x-5 '>
                            <div>
                                <img src={GlassIcon} className=" h-8" />

                            </div>
                            <div className='pt-1'>
                            200ml

                            </div>
                            </div>
                            <div className='flex flex-row space-x-2'>
                                <div>
                                08:00am
                                </div>
                                <div>
                                <img src={DeleteIcon} className="h-5" />
                                </div>
                            </div>

                        </div>
                        <div className='flex flex-row justify-between py-2 border-t-2'>
                            <div className='flex flex-row space-x-5 '>
                            <div>
                                <img src={GlassIcon} className=" h-8" />

                            </div>
                            <div className='pt-1'>
                            200ml

                            </div>
                            </div>
                            <div className='flex flex-row space-x-2'>
                                <div>
                                08:00am
                                </div>
                                <div>
                                <img src={DeleteIcon} className="h-5" />
                                </div>
                            </div>

                        </div>
                        <div className='flex flex-row justify-between py-2 border-t-2'>
                            <div className='flex flex-row space-x-5 '>
                            <div>
                                <img src={GlassIcon} className=" h-8" />

                            </div>
                            <div className='pt-1'>
                            200ml

                            </div>
                            </div>
                            <div className='flex flex-row space-x-2'>
                                <div>
                                08:00am
                                </div>
                                <div>
                                <img src={DeleteIcon} className="h-5" />
                                </div>
                            </div>

                        </div>

                    </div>
                                            
                        

                    </div>
                </div>
                <div className="flex md:w-2/3 w-full flex-col ml-3">
                    <div className='flex flex-row'>
                        <div className='w-3/4 flex flex-col'>
                            {/* Row 1 */}
                            <div className='bg-blue-300 text-2xl font-black text-blue-600 text-center py-2 rounded-lg'>
                            What did you drink today?
                            </div>
                            <div className='border-y-2 p-5 mt-2 flex flex-row justify-between'>
                                <div className='flex flex-col'>
                                    <div>
                                    <img src={GlassIcon} className="h-8 ml-2" />
                                    </div>
                                    <div className='text-blue-500'>
                                        Water

                                    </div>
                                    
                                    
                                </div>
                                <div className='flex flex-col'>
                                    <div>
                                    <img src={FlevouredIcon} className="h-8 ml-4" />
                                    </div>
                                    <div className='text-grey-300'>
                                    Flavored

                                    </div>
                                    
                                    
                                </div>
                                <div className='flex flex-col'>
                                    <div>
                                    <img src={CoffeeIcon} className="h-8 ml-2" />
                                    </div>
                                    <div className='text-grey-300 '>
                                    Coffee

                                    </div>
                                    
                                    
                                </div>
                                <div className='flex flex-col'>
                                    <div>
                                    <img src={JuiceIcon} className="h-8  ml-2" />
                                    </div>
                                    <div className='text-grey-300'>
                                    Juice

                                    </div>
                                    
                                    
                                </div>
                                
                                


                            </div>
                            <div className='flex flex-col'>
                                <div className='p-5 text-center text-2xl font-extrabold'>
                                How much did you drink?
                                </div>
                                <div className='p-5 text-center text-blue-500 text-4xl font-black'>
                                5000 ml
                                </div>
                                <div className='p-5 text-center text-blue-500 text-4xl font-black'>
                                <button className='btn bg-blue-400 w-3/4 hover:bg-blue-500'> Add</button>
                                </div>

                            </div>
                            <div className="flex flex-col ml-2">
                                <div className='flex flex-row text-2xl font-bold'>
                                Records

                                </div>
                                <div className='flex flex-row justify-between py-2 border-t-2'>
                                    <div className='flex flex-row space-x-5 '>
                                    <div>
                                        {/* <img src={GlassIcon} className=" h-8" /> */}

                                    </div>
                                    <div className='pt-1'>
                                             <span>1500</span>/5000ml

                                    </div>
                                    </div>
                                    <div className='flex flex-row space-x-2'>
                                        <div>
                                        Today
                                        </div>
                                        <div>
                                        {/* <img src={DeleteIcon} className="h-5" /> */}
                                        </div>
                                    </div>

                                </div>
                                <div className='flex flex-row justify-between py-2 border-t-2'>
                                    <div className='flex flex-row space-x-5 '>
                                    <div>
                                        <img src={GlassIcon} className=" h-8" />

                                    </div>
                                    <div className='pt-1'>
                                    200ml

                                    </div>
                                    </div>
                                    <div className='flex flex-row space-x-2'>
                                        <div>
                                        08:00am
                                        </div>
                                        <div>
                                        {/* <img src={DeleteIcon} className="h-5" /> */}
                                        </div>
                                    </div>

                                </div>
                                <div className='flex flex-row justify-between py-2 border-t-2'>
                                    <div className='flex flex-row space-x-5 '>
                                    <div>
                                        <img src={GlassIcon} className=" h-8" />

                                    </div>
                                    <div className='pt-1'>
                                    200ml

                                    </div>
                                    </div>
                                    <div className='flex flex-row space-x-2'>
                                        <div>
                                        08:00am
                                        </div>
                                        <div>
                                        {/* <img src={DeleteIcon} className="h-5" /> */}
                                        </div>
                                    </div>

                                </div>
                                <div className='flex flex-row justify-between py-2 border-t-2'>
                                    <div className='flex flex-row space-x-5 '>
                                    <div>
                                        <img src={GlassIcon} className=" h-8" />

                                    </div>
                                    <div className='pt-1'>
                                    200ml

                                    </div>
                                    </div>
                                    <div className='flex flex-row space-x-2'>
                                        <div>
                                        08:00am
                                        </div>
                                        <div>
                                        {/* <img src={DeleteIcon} className="h-5" /> */}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            
                            
                        </div>
                        <div className='1/4 '>
                            {/* Row2 */}
                           <div className="ml-2">
                               <Thermometer minValue={0} maxValue={100} className="text-blue-500" color='blue' />
                           {/* <progress class="progress progress-primary w-72 h-8" value="50" max="100"></progress>  */}
                           {/* <div class="h-30 relative max-w-xl rounded-full overflow-hidden">
                                <div class="w-full h-full bg-gray-200 absolute"></div>
                                <div class="h-full bg-green-500 absolute" style={{width:"10%"}}>mm</div>
                            </div> */}
                           </div>

                        </div>

                    </div>
                    

                </div>
                
          
          </div>
          
          
        </div>
        



       

            
      
      
      </div>

      </div>
      
      );
}
 
export default Water;