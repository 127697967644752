import React,{useEffect, useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import RecordVideo from './RecordVideo';
import Spo2 from '../module1/preview/Screen2-VitalSign1.png'
import HeartRate from '../module1/preview/Screen2-VitalSign2.png'
import BsRate from '../module1/preview/Screen2-VitalSign3.png'
// import Temp from '../module1/preview/Screen2-VitalSign4.png'
import BPressure from '../module1/preview/Screen2-VitalSign5.png'
// import EyeColor from '../module1/preview/Screen2-VitalSign6.png'
import Breathing from '../module1/preview/Screen2-VitalSign7.png'
import Healthy from '../module1/WebAppModule1_Screen5Green.png'
import Caution from '../module1/WebAppModule1_Screen6Yellow.png'
import V2 from '../module1/TabBar-Wellness@2x.png'
import Health from '../module1/TabBar-Healthiness@2x.png'
import Wellness from '../module1/TabBar-Wellness_1@2x.png'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import Smile from  '../module1/WebAppModule1_Screen8PopSmile.png'
// import { useContext } from 'react/cjs/react.production.min';
import { AuthContext } from '../App';
// import 

const ScanGuest = () => {
    // https://codesandbox.io/s/zhde4?file=/src/App.js
    const [healthstatus, setHealthStatus] = useState('initial')
    
    // const { auth, dispatchAuth } = useContext(AuthContext)
     const { auth, dispatchAuth } = useContext(AuthContext)
    useEffect(() => {
        document.getElementById("scan").click();

        // return () => {
        //     document.getElementById("stop").click();
            
        // }
        
    }, [])

     useEffect(() => {
  const interval = setInterval(() => {
    // console.log('This will run every second!');
      
      getVal('e')
  }, 2000);
  return () => clearInterval(interval);
    }, []);
    


    
    const openDialog2 = () => {
        NotificationManager.warning('Please allow camera permission');
        
    }
    const reClick = () => {
        // alert('hello');
        // document.getElementById("scan").click();
        // document.getElementById("scan").click();

        // NotificationManager.info('Loading....');
        

        // openDialog2()


        

        // setTimeout(function () {
        //     reClick();
        // }, 5000);
    }
    const getVal = (e) => {
        // alert(document.getElementById('diagnosis').innerHTML)
        // alert(e)
        // console.log(e)
        var currentval = document.getElementById('diagnosis').innerHTML
        var spo2_rate = document.getElementById('spo2_rate').innerHTML
        var heart_rate = document.getElementById('heart_rate').innerHTML
        var bs_rate = document.getElementById('bs_rate').innerHTML
        var syst_rate = document.getElementById('syst_rate').innerHTML
        var diast_rate = document.getElementById('diast_rate').innerHTML
        var br_rate = document.getElementById('br_rate').innerHTML
        
        if (currentval != '' && currentval != healthstatus) {
            setHealthStatus(currentval)
            sessionStorage.setItem('currentval', currentval)
            dispatchAuth({type:'currentval',value:currentval})
        }
        if (spo2_rate != '' && spo2_rate != auth.spo2_rate) {
            
            sessionStorage.setItem('currentval', spo2_rate)
            dispatchAuth({type:'spo2_rate',value:spo2_rate})
        }
        if (heart_rate != '' && heart_rate != auth.heart_rate) {
            
            sessionStorage.setItem('heart_rate', heart_rate)
            dispatchAuth({type:'heart_rate',value:heart_rate})
        }
        if (bs_rate != '' && bs_rate != auth.bs_rate) {
            
            sessionStorage.setItem('bs_rate', bs_rate)
            dispatchAuth({type:'bs_rate',value:bs_rate})
        }
        if (syst_rate != '' && syst_rate != auth.syst_rate) {

            sessionStorage.setItem('syst_rate', bs_rate)
            dispatchAuth({type:'syst_rate',value:syst_rate})
        }
        if (diast_rate != '' && diast_rate != auth.diast_rate) {

            sessionStorage.setItem('diast_rate', diast_rate)
            dispatchAuth({type:'diast_rate',value:diast_rate})
        }
        if (br_rate != '' && br_rate != auth.br_rate) {

            sessionStorage.setItem('br_rate', br_rate)
            dispatchAuth({type:'br_rate',value:br_rate})
        }
        
        
        // var number=document.getElementById("diagnosis").value
        // alert(number)
        // console.log(number)
        // getVal
    }
    return (<>
        <div class="rounded-lg flex flex-col md:flex-row mx-0 bg-white shadow-2xl">
            {/* {auth.spo2_rate}
            auth.spo2_rate */}
            <NotificationContainer/>
            {/* <div class="flex flex-row md:flex-col mr-1 md:w-60 w-auto ">
                
                
                <div className=" bg-blue-200 h-16 md:mx-0 mx-2">
                    <div className="my-5 flex">

                        <div className="ml-4">
                            <img src={V2} className="w-7" />
                        </div>
                        <div className="ml-2">
                           <Link to='/scan'> Vital Signs</Link>

                        </div>
                        
                        
                        
                    </div>  
                </div>
                <div className=" h-16 md:mx-0 mx-2">
                    <div className="my-5 flex">
                        <div className="ml-4">
                            <img src={Health} className="w-7" />
                        </div>
                        
                        <div className="ml-2">
                           <Link to='/healthness'> Healthness</Link>

                        </div>
                    </div>

                </div>
                <div className=" h-16 md:mx-0 mx-2 border-y-2">
                    <div className="my-5 flex">
                        
                        <div className="ml-4">
                            <img src={Wellness} className="w-7" />
                        </div>
                        
                        <span className="ml-2">
                           <Link to=''> Wellness </Link>

                            </span>
                            
                    </div>
                   

                </div>
                
                
            </div> */}
            <div class="w-50 ml-5">
                {/* 2 */}
                {/* {healthstatus} */}
                
                <div id="vital_history" > 
                    <h4> Diagnosis :  <b id="diagnosis" ></b></h4>
                    
                </div>
                <br />
                <div className="wrapper">
                <div>
                    <div className="navi bg-black">
                    <video hidden id="webcam" width="640" height="480"></video>
                    <canvas id="canvas" width="640" height="480"></canvas>
                    
                    </div>
                </div>
                <div className=" w-full h-full grid grid-flow-row grid-cols-2" id="infoi">

                  <div className="flex flex-col m-4">
                      {/* Left part */}

                      <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${Spo2})`}} >
                          < br />
                          < br />
                          <b className="ml-7 text-white" id="spo2_rate" > - </b> <span className='text-white'>%</span> 
                      </div>
                      <span className="text-sm text-white font-bold" >SPO <sup>2</sup>Levels</span>
                      <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${HeartRate})`}} >
                          < br />
                          
                          <b className="ml-7 mt-10 text-white" id="heart_rate" > </b> < br /><span className='text-white font-bold ml-6'>BPM</span> 
                      </div>
                      <span className="text-sm text-white font-bold" >Heart Rate</span>
                      <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BsRate})`}} >
                          < br />
                          {/* < br /> */}
                          
                                <b className="ml-7 mt-10 text-white" id="bs_rate" > </b> 
                                <br />
                                <span className='text-white font-bold ml-3'>mg/dL</span> 
                      </div>
                            <span className="text-sm text-white font-bold" >Blood Sugar</span>
                            
                      {/* <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${Temp})`}} >
                          < br />
                          
                          
                          <b className="ml-7 mt-10 text-white" id="temperature" > N/A</b> <br /> <span className='text-white font-bold ml-7'>C</span> 
                      </div>
                      <span className="text-sm text-white font-bold" >Temperature</span> */}
                      
                  </div>
                  <div className="flex flex-row-reverse mt-4">
                            {/* right part */}
                            

                      <div className="flex flex-col">
                          <div>
                          <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${BPressure})`}} >
                          < br />
                          
                            <span className="ml-2 mt-10 text-white"> <b id="syst_rate"> - </b> / <b id="diast_rate"> - </b> </span>
                            <br /> <span className='text-white font-bold ml-6'>mmHg</span> 
                          
                          {/* <b className="ml-7 mt-10 text-white" id="heart_rate" > </b> < br /><span className='text-white font-bold ml-6'>BPM</span>  */}
                      </div>
                      <span className="text-sm text-white font-bold mr-3" >Blood Pressure</span>

                      </div>
                      <div>
                          {/* <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${EyeColor})`}} >
                          < br />
                          < br />
                          
                          <b className="ml-7 mt-10 text-white" id="eye" > N/A </b> 
                      </div>
                      <span className="text-sm text-white font-bold" >Eye Redness</span> */}

                      </div>
                      <div>
                          <div className="bg-no-repeat h-20 bg-contain" style={{ backgroundImage: `url(${Breathing})`}} >
                          < br />
                          
                            <span className="ml-7 mt-10 text-white"> <b id="br_rate"> - </b> </span>
                            <br /> <span className='text-white font-bold ml-6'>RPM</span> 
                          
                          {/* <b className="ml-7 mt-10 text-white" id="heart_rate" > </b> < br /><span className='text-white font-bold ml-6'>BPM</span>  */}
                      </div>
                      <span className="text-sm text-white font-bold" >Breathing Rate</span>

                      </div>

                      </div>
                      
                      
                  </div>

              </div>
  </div>
  <div className="flex m-3">
      <div>
          <button id="scan" onClick={()=>reClick()} className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Start Scanning</button>
          {/* <button onClick={()=>reClick()}>Test Click</button> */}
    
        
      </div>
      <div className='ml-6'>
          <div>
              <button id="stop" disabled className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Stop Scanning</button>
          </div>
      </div>
  </div>
  {/* <span onClick={(e)=>getVal(e)}>
      <button  id="diagnosis" >Get val</button>
      
  </span> */}
  
  
   
        
            </div>
            <div class=" ml-8 rounded-lg border-red-200  w-96">
                {/* flex 3 */}
                <div className="text-blue-500 font-extrabold text-3xl text-center w-64">
                    Result
                </div>
                <div className=" min-h-80 rounded-lg bg-blue-400 border-white border-red-200">
                    {healthstatus == 'Healthy' ? <>
                        <img src={Healthy} alt='Healthy' className="ml-20 py-10" />
                        <div className="text-white font-extrabold text-center text-2xl">
                            You are good to go
                        </div>
                    
                    </> : <></>}
                    {healthstatus == 'initial' ? <>
                        {/* <img src={Healthy} alt='Healthy' className="ml-20 py-10" /> */}
                        <div className="text-white font-extrabold text-center text-2xl h-80 ">
                        <div className="justify-between py-20">   Welcome to Non-Contact
                            Health Monitoring System </div>
                        </div>
                    
                    </> : <></>}
                    {healthstatus == 'Loading' ? <>
                        {/* <img src={Healthy} alt='Healthy' className="ml-20 py-10" /> */}
                        <div className="text-white font-extrabold text-center text-2xl h-80 ">
                        <div className="justify-between py-20">   Welcome to Non-Contact
                            Health Monitoring System </div>
                        </div>
                    
                    </> : <></>}
                    {healthstatus == 'Caution' ? <>
                        <img src={Caution} alt='Caution' className="ml-20 py-10" />
                        <div className="text-white font-extrabold text-center text-2xl">
                            We identified some Abnormalities Please scan again
                        </div>
                    
                    </> : <> &nbsp;</>}

                </div>
                <div className='py-5'>
                    {/* <Link to="/scan-history" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Scan History</Link> */}

                    <label for="my-modal" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Scan History</label>

                </div>
                
                
            </div>
        </div>

        <input type="checkbox" id="my-modal" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box w-96">
                <img src={Smile} className="object-center ml-14" />
            <h3 class="font-bold text-2xl text-blue-500 text-extrabold text-center">
                Do you want to save and check your history
            </h3>
                <div className="flex flex-col">
                    <div className="my-5">
                        <Link to="/" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Login</Link>
                    </div>
                    <div>
                        <Link to="/register" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-blue-400  bg-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-2 border-blue-500'> Sign Up</Link>

                    </div>
                    <div className="my-5 flex flex-col">
                         <label for="my-modal" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 hover:text-white focus:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Scan History
                            
                           <br /> 2 free scan remaining
                        </label>

                    </div>
                </div>
            <div class="modal-action">
            {/* <label for="my-modal" class="btn">Yay!</label> */}
            </div>
        </div>
        </div>
    </>)
}
 
export default ScanGuest;