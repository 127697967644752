import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import url from './url';
import { Oval } from 'react-loader-spinner';

const ForgotPassword = () => {

    const [email, setEmail] = useState('')
    const [errors, setErrors]=useState([])
    const [error, setError]=useState('')
    const [spin, setSpin] = useState('')

    const passwordReset = () => {

        setErrors([])
        setError('')
        setSpin(true)

        axios.post(url + 'auth/reset',{email})
            .then(res => {

                // console.log('res')
                // console.log(res)
                setError(res.data.message)
                    
            }).catch(e => {

                console.log(e)

                if (Array.isArray(e.response.data.message)) {
                    setErrors(e.response.data.message)
                    
                } else {
                    setError(e.response.data.message)
                }
                console.log('e.response.data')
                console.log(e.response.data)
                // setErrors(e.response.data.message)
                    
            }).then(r => {
                setSpin(false)
                
            })
        
    }
    const rendererror = errors.map(error => (
        <ol>
            <li>{error}</li>
        </ol>
        
    ))
    return (<>
        <div className="md:mx-96 pt-6">

           

            <div className="border-2 p-3 border-blue-400 rounded-lg">
                 
                <div className="text-extrabold text-2xl py-5">
                {error}
                Enter your email address
            </div>
            
                <div>
                    <input type="text" placeholder="Type here" class="input input-bordered input-info w-full max-w-xs "
                        // onClick={() => passwordReset()}
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                    />
                    <div className='flex text-2xl text-red-900 text-bold'>
                        {rendererror}
                        {error}

            </div>
                </div>  
                {/* <div className="flex"> */}
                {spin ? <>
                <div className="flex">
                  <Oval
  // ariaLabel="loading-indicator"
  ariaLabel="loading"
  height={40}
  width={40}
  strokeWidth={5}
  color="blue"
  secondaryColor="white"
/>
</div>
                
                </> : <></>}

                {/* </div> */}

                <div className="flex-row md:flex">
                    <div className='flex md:my-10 my-2 '>
                    <button class="btn bg-blue-400 hover:bg-blue-500" onClick={()=>passwordReset()}>Reset Password</button>
                </div>  
                <div className='flex md:my-10 md:ml-5 my-2'>
                    <Link to='/' class="btn bg-blue-300 hover:bg-blue-400" onClick={()=>passwordReset()}>Back to login</Link>
                </div>

                </div>
                  
                
                
            </div>
        </div>
    
    </>);
}
 
export default ForgotPassword;