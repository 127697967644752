import React from 'react';

const RegistrationSuccess = () => {
    return (<>
        <div className="flex flex-row flex-wrap  md:px-96 px-0  pt-7  ">

            <div className='border-2 rounded-lg border-blue-400'>
   
   
                <div className="text-center"> Thank you for registering </div> <br />

<p className="my-4">You will be receiving a confirmation email shortly to confirm the registration.Please click on the link in the email to confirm the registration and activate your account. </p>

<p className="my-3">It is possible that the email could end up in the spam folder, so please check there just in case. If you do find an email in your spam folder, do not forget to mark it as safe to ensure that you receive future messages from us.</p>
            </div>   

        </div>
        
    </>);
}
 
export default RegistrationSuccess;