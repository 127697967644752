import logo from './logo.svg';
import './App.css';
import Login from './components/auth/Login';
import { Routes, Route, Link } from "react-router-dom";
import Navbar from './components/Navbar';
import Register from './components/auth/Register';
import Guideline from './components/GuideLine';
import Scan from './components/Scan';
import Healthness from './components/Healthness';
import CustomChart from './components/CustomChart';
import MyApp from './components/Calender';
import Flex from './components/test/Flex';
import { createContext, useReducer } from 'react';
import Logout from './components/auth/Logout';
import RegistrationSuccess from './components/auth/RegistrationSuccess';
import Verify from './components/auth/Verify';
import ForgotPassword from './components/ForgotPassword';
import ScanGuest from './components/ScanGuest';
import ProfileSetup from './components/ProfileSetup';
import ScanV4 from './components/ScanV4';
import DailyPage from './components/DailyPage';
import Wellness from './components/wellness/Wellness';
import CompleteReset from './components/auth/CompleteReset';
import BMI from './components/wellness/BMI';
import BMIStatitics from './components/wellness/BMIStatitics';
import Execise from './components/wellness/Execise';
import Scan5 from './components/Scan5';
import Water from './components/wellness/Water';
import Sleep from './components/wellness/Sleep';

export const AuthContext = createContext()

const initialauth = {
    logged_in: sessionStorage.getItem('logged_in',false),
  user: sessionStorage.getItem('user'),
  token: sessionStorage.getItem('token'),

  currentval :sessionStorage.getItem('currentval'),
  spo2_rate :sessionStorage.getItem('spo2_rate'),
  heart_rate :sessionStorage.getItem('heart_rate'),
  bs_rate :sessionStorage.getItem('bs_rate'),
  syst_rate:sessionStorage.getItem('syst_rate'),
  diast_rate :sessionStorage.getItem('diast_rate'),
  br_rate:sessionStorage.getItem('br_rate'),

  
    
}
    const authreducer = (state, action) => {
  switch (action.type) {

    case 'login_status':
          return {...state,logged_in : action.value}
    case 'user':
      return {...state, user: action.value}
    case 'token':
      return {...state, token: action.value}
    case 'currentval':
      return {...state, currentval: action.value}
    case 'spo2_rate':
      return {...state, spo2_rate: action.value}
    case 'heart_rate':
      return {...state, heart_rate: action.value}
    case 'bs_rate':
      return {...state, bs_rate: action.value}
    case 'syst_rate':
      return {...state, syst_rate: action.value}
    case 'diast_rate':
      return {...state, diast_rate: action.value}
    case 'br_rate':
      return {...state, br_rate: action.value}
    case 'close_modal':
      return {...state, signup: false}
    case 'open_modal':
      return {...state, signup: true}
    default:
      return state
  }
};
function App() {
  const [auth, dispatchAuth] = useReducer(authreducer, initialauth)
  return (
    // https://app-test.docsun.health/auth/password-reset/JKDAp45X4y6mLZ6XZ4IozUy04VuDaPc5mX4GE5h7wL4D7vTGKRiO0ZbSthnm#/
    <AuthContext.Provider value={{ auth, dispatchAuth }}>
    <div className='bg-white'>
      
      <Navbar />
      {/* <CustomChart /> */}
      <Routes className="bg-white">
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/guideline" element={<Guideline />} />
        <Route path="/scan" element={<Scan />} />
        <Route path="/free-scan" element={<ScanGuest />} />
        <Route path="/healthness" element={<Healthness />} />
        <Route path="/calender" element={<MyApp />} />
        <Route path="/flex" element={<Flex />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/verify-account/:token" element={<Verify />} />
        <Route path="/recovered-password" element={<ForgotPassword />} />
        <Route path="/profile-setup" element={<ProfileSetup />} />
        <Route path="/scanv4" element={<ScanV4 />} />
        <Route path="/dailystats" element={<DailyPage />} />
        <Route path="/wellness" element={<Wellness />} />
        <Route path="/auth/password-reset/:id" element={<CompleteReset />} />
        <Route path="/bmi" element={<BMI />} />
        <Route path="/bmistatistics" element={<BMIStatitics />} />
        <Route path="/execise" element={<Execise />} />
        <Route path="/scan5" element={<Scan5 />} />
        <Route path="/water" element={<Water />} />
        <Route path="/sleep" element={<Sleep />} />
        
          {/* <Route path="about" element={<About />} /> */}
      </Routes>
     
      </div>
      </AuthContext.Provider>
  );
}

export default App;
