import React from 'react';
// import { Chart } from "react-google-charts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Example from '../CustomChart';
import V2 from '../../module1/TabBar-Wellness@2x.png'
import Health from '../../module1/TabBar-Healthiness@2x.png'
import WellnessIcon from '../../module1/TabBar-Wellness_1@2x.png'
import { Link } from 'react-router-dom';
import DashPie from '../charts/DashPie';
import MyApp from '../Calender';
import Normal from '../../module1/WebAppModule1_Screen5Green.png'
import BodyMassIcon from '../module3/Body Mass Index (BMI)@2x.png'
import ExerciseIcon from '../module3/Exercise@2x.png'
import WaterIcon from '../module3/Water@2x.png'
import FoodIcon from '../module3/Food@2x.png'
import SleepIcon from '../module3/Sleep@2x.png'
import MenstralIcon from '../module3/Menstrual Cycle@2x.png'
import Gauge from '../charts/Gauge';
import BackIcon from '../../module1/SignInUserToggleButton.png'
import ScanButtonIcon from '../../module1/SignInUserScanButton.png'

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Wellness = () => {
  return (

    <>
    
    <div className="flex  md:flex-row flex-col">
      {/* <Example /> */}
      <div class="flex flex-row md:flex-col mr-1 md:w-60 w-auto justify-between border-r-2  h-screen ">

{/* 1 */}
<div className="flex flex-row md:flex-col mr-1 md:w-60 w-auto  h-screen">
    {/* menus */}
    <div className="h-16 md:mx-0 mx-2">
    <div className="my-5 flex">

        <div className="ml-4">
            <img src={V2} className="w-7" />
        </div>
        <div className="ml-2">
           <Link to='/scan'> Vital Signs</Link>

        </div>
        
        
        
        </div>  
</div>
<div className=" h-16 md:mx-0 mx-2">
    <div className="my-5 flex">
        <div className="ml-4">
            <img src={Health} className="w-7" />
        </div>
        
        <div className="ml-2">
           <Link to='/healthness'> Healthness</Link>

        </div>
    </div>

</div>
<Link to='/wellness'>
<div className=" h-16 md:mx-0 mx-2 border-y-2 bg-blue-200 border-r-4 border-r-blue-500">
    <div className="my-5 flex">
        
        <div className="ml-4">
            <img src={WellnessIcon} className="w-7" />
        </div>
        
        <span className="ml-2 ">
            Wellness 

            </span>
            
    </div>
   

</div>
</Link>
    
</div>
<div className="md:visible invisible">
    
    <div className="flex flex-col">
        <div>
            <img src={ScanButtonIcon} />
        </div>
        <div className=" bg-gray-400 mr-7 h-20">
            <span className=" my-8 mx-8 pt-3 mt-8"> Version: 2.3.1 </span>
        </div>
        <div className="h-20">
            <img src={BackIcon} className=" mx-12 my-5" />
        </div>


    </div>

</div>




</div>
      
          <div className="flex bg-white flex-col md:flex-row w-full">
            <div className="flex md:w-1/2 w-full">
              <div className="border-2 rounded-lg w-full flex flex-col bg-gradient-to-b from-blue-500 to-white">
                <div className="text-center text-extrabold text-white text-3xl pt-3">
                  Todays Wellness Score
                
                </div>
                <div className="border-t-2 border-white">
                  <Gauge />
                  
                </div>
                <div className="text-black text-center text-extrabold text-3xl">
                EXCELLENT
                
                  
              </div>
              <hr className="mx-9" />
                  <div className="text-1xl text-bold mx-4">
                    <p>
                      The Health Score summarizes the health status of an individual into one number that measures the overall health and wellbeing of a person in real time.
                    </p>
                    <p>
                    The Health Score indicator is a scientifically calculated number from 1 (low) to 1000 (high) that moves up or down, depending on how your body, emotional wellbeing, or lifestyle data change.
                    </p>
                  </div>

              

                </div>
            </div>
          <div className="flex md:w-1/2 w-full flex-col">
            <Link to="/bmi">
            <div className="rounded-lg md:mx-6 mx-1 md:mt-1 mt-4 bg-orange-400 flex flex-row">
              {/* one card */}
              <div>
                <img src={BodyMassIcon} className=" h-20 p-2" alt='Body Mass' />
              </div>
              <div className="flex flex-col">
                <span className="text-white text-2xl text-extrabold"> Body Mass Index (BMI)
                
                </span>
                <span className="text-sm">
                    
                    Calculate your BMI
                </span>
                <hr></hr>
                <div className='text-sm text-white'>
                  Last Updated: 04/01/22 10:56
                </div>
              </div>
            </div>
            </Link>
            <Link to="/execise">
            <div className="rounded-lg md:mx-6 mx-1 md:mt-1 mt-4 bg-red-700 flex flex-row">
              {/* one card */}
              <div>
                <img src={ExerciseIcon} className=" h-20 p-2"  alt="Excercise"/>
              </div>
              <div className="flex flex-col">
                <span className="text-white text-2xl text-extrabold"> Exercise
                
                </span>
                <span className="text-sm">
                    
                    Track your excercise
                </span>
                <hr></hr>
                <div className='text-sm text-white'>
                  Last Updated: 04/01/22 10:56
                </div>
              </div>
            </div>
            </Link>
            <Link to="/water">
            <div className="rounded-lg md:mx-6 mx-1 md:mt-1 mt-4 bg-blue-500 flex flex-row">
              {/* one card */}
              <div>
                <img src={WaterIcon} className=" h-20 p-2"  alt="Water"/>
              </div>
              <div className="flex flex-col">
                <span className="text-white text-2xl text-extrabold"> Water
                
                </span>
                <span className="text-sm">
                    
                    Set your daily water goal
                </span>
                <hr></hr>
                <div className='text-sm text-white'>
                  Last Updated: 04/01/22 10:56
                </div>
              </div>
            </div>
            </Link>
            <div className="rounded-lg md:mx-6 mx-1 md:mt-1 mt-4 bg-green-500 flex flex-row">
              {/* one card */}
              <div>
                <img src={FoodIcon} className=" h-20 p-2"  alt="Food"/>
              </div>
              <div className="flex flex-col">
                <span className="text-white text-2xl text-extrabold"> Food
                
                </span>
                <span className="text-sm">
                    
                      Start logging your food
                </span>
                <hr></hr>
                <div className='text-sm text-white'>
                  Last Updated: 04/01/22 10:56
                </div>
              </div>
            </div>
            <Link to="/sleep">
            <div className="rounded-lg md:mx-6 mx-1 md:mt-1 mt-4 bg-purple-600 flex flex-row">
              {/* one card */}
              <div>
                <img src={SleepIcon} className=" h-20 p-2"  alt="Sleep"/>
              </div>
              <div className="flex flex-col">
                <span className="text-white text-2xl text-extrabold"> Sleep
                
                </span>
                <span className="text-sm">
                    
                      Start tracking your sleep
                </span>
                <hr></hr>
                <div className='text-sm text-white'>
                  Last Updated: 04/01/22 10:56
                </div>
              </div>
            </div>
            </Link>
            <div className="rounded-lg md:mx-6 mx-1 md:mt-1 mt-4 bg-pink-400 flex flex-row">
              {/* one card */}
              <div>
                <img src={MenstralIcon} className=" h-20 p-2"  alt="Menstral"/>
              </div>
              <div className="flex flex-col">
                <span className="text-white text-2xl text-extrabold"> Menstrual Cycle
                
                </span>
                <span className="text-sm">
                    
                      Tracking your menstrual cycle
                </span>
                <hr></hr>
                <div className='text-sm text-white'>
                  Last Updated: 04/01/22 10:56
                </div>
              </div>
            </div>

            </div>
          
          </div>



       

            
      
      
      </div>

      </>
      
      );
}
 
export default Wellness;