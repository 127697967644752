import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { Link, Navigate } from 'react-router-dom';
import { AuthContext } from '../../App';

import Image1 from '../../module1/Screen12@2x.png'
import url from '../url';
const Login = () => {

  const [success, setSucess] = useState(false)
  const [email, setEmail]=useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [errors, setErrors] = useState([])
  const [redirect, setRedirect]=useState(false)
  const { auth, dispatchAuth } = useContext(AuthContext)
  const [spin, setSpin]=useState(false)
  


  // var user2=JSON.parse(auth.user)
  
  // console.log(user2)
  // console.log(user2.email)
  // console.log(user2.id)

  // prin

  useEffect(() => {
    if (auth.logged_in) {
    setRedirect(true)
    return;
  }
  },[])
  const login = () => {
    // setSucess(true)
    // alert('hello')
    // var ac = sessionStorage.getItem('user');

    // console.log('ac')
    // console.log(ac)
    // console.log(JSON.parse(ac))


    // return
    
    setError('')
    if (email == '' || password == '') {
      setError('All fields are required')
      return
    }
    setSpin(true)
    axios.post(url + 'wellness/user/login',{email,password})
      .then(res => {
        console.log(res.data.result)
        // console.log(res.response.data)
        if (res.data.success) {
          // alert('success')
          // 1. Dispatch to context 
          var abc= res.data.result
          dispatchAuth({type:'login_status',value:true})
          dispatchAuth({type:'user',value:JSON.stringify(abc)})
          // 2. write to session storage

          sessionStorage.setItem('logged_in', true);
          sessionStorage.setItem('user', JSON.stringify(abc));
          sessionStorage.setItem('token', res.data.result.accessToken);

          setSucess(true)
        } else {
          // setError('Wrong username or password')
        }
            
      }).catch(e => {

        // setErrors(e.response.data.result)
        console.log(e)
        
        setError('Wrong username or password')
        if (e.response) {
          // console.log(e.response);
        }
      }).then(r => {
        // console.log(r)
        console.log('r')
        setSpin(false)
            
          })
    
  }

  const rendererror = errors.map(error => (
    <ol>
      <li>{ error}</li>
    </ol>
  ))
  
  return (<>
    {success ? <>
      <Navigate to="/guideline" />
    
    </> : <></>}
    {redirect ? <>
        <Navigate to="/scan" />
        
        
    
    </> : <></>}
    
        <div class="grid grid-cols-1 md:grid-cols-2 bg-blue-400 rounded-xl mx-0 md:mx-10 h-full border-gray-200">
  {/* <div class="col-span-2">01</div>
  <div class="col-span-2">02</div> */}
  <div className="mx-24 py-10">
    <img src={Image1} />
      
  </div>
  
      <div className="  bg-blue-400 border rounded-xl pt-5 ">
        
      <div className="text-lg text-white  text-center text-3xl my-4 font-extrabold ">
          Welcome to Non-Contact<br />
Health Monitoring System
        </div>
        {/* {email}
        {password} */}
        {rendererror}
      <div className="bg-indigo-50 rounded-xl">
          <div className="min-h-full flex items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          
          {/* <form className="mt-8 space-y-6 justify-between" action="#" method="POST"> */}
              {/* <input type="hidden" name="remember" defaultValue="true" /> */}
              <div className="flex flex-row text-center text-extrabold text-red-500">
                {error}

              </div>
            <div className="rounded-md shadow-sm">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address 
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                />
              </div>
              <div className='pt-3'>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                />
              </div>
            </div>

            

            <div>
              
                <div className="flex flex-row">
                  <div className="w-1/3">
                    <div class="form-control">
                      <label class="cursor-pointer label">
                        
                        <input type="checkbox"  class="checkbox checkbox-accent" /> <span class="label-text ml-0">Remember me </span>
                      </label>
                    </div>
                    
                  </div>
                  <div className="ml-4 w-2/3">
                    {/* login */}
                    <Link to="/recovered-password" className='group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Forgot Password</Link>
                  </div>
                   </div>
                
         
               {spin ? <>
                
                  <Oval
  // ariaLabel="loading-indicator"
  ariaLabel="loading"
  height={40}
  width={40}
  strokeWidth={5}
  color="blue"
  secondaryColor="white"
/>
                
                </> : <></>}
              
              </div>
             
            <div>
              
                
               
              
                    {/* <Link to="/register" className='group relative w-full flex justify-center px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Register</Link> */}
                
                
                <div className="flex flex-row">
                  <div className="mx-3 w-1/2">
                    <Link to="/register" className='group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Register</Link>
                  </div>
                  <div className='w-1/2'>
                    <button  onClick={()=>login()} className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ml-10'>
                      
                      
                  Login</button>
                    
                  </div>
                </div>
              
            </div>
            <div>
              <Link to="/scan" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Scan For Free</Link>
              
            </div>
          {/* </form> */}
        </div>
      </div>

      </div>
      
  </div>
  
</div>
    </>);
}
 
export default Login;