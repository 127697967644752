import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { AuthContext } from '../../App';


const Logout = () => {
    const [redirect, setRedirect] = useState(false)
     const { auth, dispatchAuth } = useContext(AuthContext)
   
    useEffect(() => {


        clearSessions();
        
    }, [])
    
    const clearSessions = () => {

        dispatchAuth({type:'login_status',value:false})
        //   dispatchAuth({type:'user',value:{}})
          // 2. write to session storage

          sessionStorage.setItem('logged_in', false);
        //   sessionStorage.setItem('user', JSON.stringify(abc));
        sessionStorage.setItem('token', '');
        sessionStorage.removeItem('logged_in')
        setRedirect(true)
        
    }
    return (<>
        {redirect ? <>
            <Navigate to='/' />
        
        </> : <>
                <Oval
  // ariaLabel="loading-indicator"
  ariaLabel="loading"
  height={40}
  width={40}
  strokeWidth={5}
  color="blue"
  secondaryColor="white"
/>
        
        </>}
    </>);
}
 
export default Logout;