import React, { useState } from 'react';

import Avatar  from '../module1/avatar.png'


const ProfileSetup = () => {
    const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
    
    const onChangePicture = e => {
    if (e.target.files[0]) {
      console.log("picture: ", e.target.files);
        setPicture(e.target.files[0]);
        // dispatchQuestion({type:'picture', value:e.target.files[0]})
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };
    return (<>
        <div className="flex md:mx-24 border-2 border-blue-500 rounded-lg md:flex-row flex-col">
            <div className="flex md:w-1/2 flex-col border-r-2 border-blue-500 px-3">
                <div className="text-center text-2xl text-blue-500 text-extrabold flex">About Me</div>
                <div className=" flex justify-between flex-row">
                    <div>
                        <button onClick={()=>document.getElementById('profile-image').click()} className="btn btn-sm btn-primary" style={{whiteSpace:'nowrap'}}>  <span style={{whiteSpace:'nowrap'}}></span>Upload Photo</button>

                    </div>

                    <div>
                        {!imgData ? <>
                            <img src={Avatar} className="h-16 rounded-full" />
                        </> : <>
                                <img className=" h-32 rounded-lg" src={imgData} />
                    </>}
                    <input id="profilePic" type="file" onChange={onChangePicture} id="profile-image" accept="image/*" style={{ visibility: "hidden" }} />

                    </div>
                    
                    
                </div>
                <div className="flex flex-col">
                    <label class="label">
                        <span class="label-text">Gender</span>
                        
                    </label>
                    <select class="select select-accent w-full max-w-xs">
                    
                    <option>Female</option>
                    <option>Male</option>
                    
                    </select>

                </div>
                <div className="flex flex-row">
                    <div className="w-2/3">
                        <div className="flex flex-col">
                            <label class="label">
                                <span class="label-text">Weight</span>
                                
                            </label>
                            <input type="text" placeholder="Type here" class="input input-bordered input-accent w-full max-w-xs"></input>

                        </div>
                       
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col">
                            <label class="label">
                                <span class="label-text">UOM</span>
                                
                            </label>
                            <select class="select select-accent w-full max-w-xs">
                            
                            <option>KG</option>
                            <option>Pounds</option>
                            
                            </select>

                        </div>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="w-2/3">
                        <div className="flex flex-col">
                            <label class="label">
                                <span class="label-text">Height</span>
                                
                            </label>
                            <input type="text" placeholder="Height" class="input input-bordered input-accent w-full max-w-xs"></input>

                        </div>
                       
                    </div>
                    <div className="w-1/3">
                        <div className="flex flex-col">
                            <label class="label">
                                <span class="label-text">UOM</span>
                                
                            </label>
                            <select class="select select-accent w-full max-w-xs">
                            
                            <option>CM</option>
                            <option>Inches</option>
                            
                            </select>

                        </div>
                    </div>
                </div>
                <div className="flex flex-row">
                    <div className="w-2/3">
                        <div className="flex flex-col">
                            <label class="label">
                                <span class="label-text">Age</span>
                                
                            </label>
                            <input type="text" placeholder="Age" class="input input-bordered input-accent w-full max-w-xs"></input>

                        </div>
                       
                    </div>
                    
                </div>
            </div>
            <div className="flex w-1/2 px-5 flex-col">
                <div className="text-center text-blue-500 text-extrabold text-3xl">
                    Account Settings

                </div>
                <div className="w-full">
                        <div className="flex flex-col w-1/2">
                            <label class="label">
                                <span class="label-text">Language</span>
                                
                            </label>
                            <select class="select select-accent w-full max-w-xs">
                            
                            <option>En</option>
                            <option>Tw</option>
                            
                            </select>

                        </div>
                       
                    </div>
                <div className="w-full">
                        <div className="flex flex-col w-1/2">
                            <label class="label">
                                <span class="label-text">Change Password</span>
                                
                            </label>
                            <input type="password" placeholder="Change password" class="input input-bordered input-accent w-full max-w-xs"></input>

                        </div>
                       
                    </div>
                <div className="w-full">
                        <div className="flex flex-col w-1/2">
                            <label class="label">
                                <span class="label-text">Change Email</span>
                                
                            </label>
                            <input type="text" placeholder="Change Email" class="input input-bordered input-accent w-full max-w-xs"></input>

                        </div>
                       
                    </div>
                <div className="w-full object-right pt-10">
                    <button class="btn btn-success">Save bio data</button>
                        
                       
                    </div>
            </div>
            
        </div>
    
    </>);
}
 
export default ProfileSetup;