import React from 'react';

const Flex = () => {
    return ( 

        <>
        <div className="flex flex-wrap flex-row">
            <div className="flex flex-row">
                <div className="flex">

                    Hello

                </div>
                <div className="flex">

                    World

                </div>
            </div>  

            
            </div>

            <div className="flex">
                <div className="flex flex-row">
                <div>
                    Hello
                </div>
                <div>
                    World
                </div>

            </div>
                
            </div>
            </>
     );
}
 
export default Flex;