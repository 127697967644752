import React,{useContext, useState,useEffect} from 'react';
import { Link, Navigate } from 'react-router-dom';


import Image1 from '../../module1/Screen12@2x.png'
import url from '../url';
import axios from 'axios';
import { Oval } from 'react-loader-spinner';
import { AuthContext } from '../../App';
const Register = () => {
   const [error, setError] = useState('')
  const [errors, setErrors] = useState([])
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [spin, setSpin] = useState('')
  const [success, setSuccess] = useState(false)
  const [redirect, setRedirect]=useState(false)

  const { auth, dispatchAuth }= useContext(AuthContext)


  useEffect(() => {
    if (auth.logged_in) {
    setRedirect(true)
    return;
  }
  },[])
  

  const signUp = () => {
    setError('')
    setErrors([])
    

    var postData = {
    email: email,
    password: password,
    name: username,
    user_key: ''
  };

  let axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      language: 'en',
      "Access-Control-Allow-Origin": "*",
    }
  };
    
    

    if (email == '' || password == '' || username == '') {
      setError('All fields required')
      return;
    }
    if (password != confirmpassword) {
      setError('Password and confirm password mismatch');
      return;

    }
    setSpin(true)
  
    axios.post(url+ 'wellness/user/register', postData
    )
      .then(res => {
        console.log(res)
        console.log('res')
        // setError('e.message')
        setSuccess(true)

      }).catch(e => {
        // setError('Email already taken')
        // console.log('e.message')
        // console.log(e.response.data.result)
        setErrors(e.response.data.result)
        // console.log(JSON.parse(e))
        // console.log(res)

      }).then(r => {
        // setSuccess(true)
        // console.log('r')
        setSpin(false)

      }
      )

    
  }

  const rendererror = errors.map(error => (
    // <ol>
      <div>{ error}</div>
    // </ol>
  ))
  return (<>
      
    <div class="grid grid-cols-1 md:grid-cols-2 bg-blue-400 rounded-xl mx-0 md:mx-10 h-full border-gray-200">
      {redirect ? <>
        <Navigate to="/scan" />
       
        
        
    
    </> : <></>}
      {success ? <>
        <Navigate to="/registration-success" />
       
        
        
    
    </> : <></>}
  {/* <div class="col-span-2">01</div>
  <div class="col-span-2">02</div> */}
  <div className="mx-24 py-10">
    <img src={Image1} />
      
  </div>
  
  <div className="  bg-blue-400 border rounded-xl pt-10 pt-4">
      <div className="text-lg text-white  text-center text-3xl ">
          Welcome to Non-Contact<br />
Health Monitoring System
      </div>
      <div className="bg-indigo-50 rounded-xl">
          <div className="min-h-full flex items-center justify-center py-2 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
              <div className="flex flex-row text-red-700 text-extrabold">
                {error}
                {/* {rendererror} */}
          </div>
              <div className="flex flex-col text-red-700 text-extrabold">
                {/* {error} */}
                {rendererror}
          </div>
          
          {/* <form className="mt-8 space-y-6 justify-between" action="#" method="POST"> */}
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm">
              {/* <div className='py-1'>
                <label htmlFor="email-address" className="sr-only">
                  First Name
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="First Name"
                />
              </div> */}
              <div className='py-1'>
                <label htmlFor="email-address" className="sr-only">
                 Username
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="text"
                  autoComplete="email"
                  required
                  value={username}
                  onChange={(e)=>setUsername(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="User name"
                />
              </div>
              <div className='py-1'>
                <label htmlFor="email-address" className="sr-only">
                  Email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e)=>setEmail(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email"
                />
              </div>
              <div className='py-1'>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                   value={password}
                  onChange={(e)=>setPassword(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
              <div className='py-1'>
                <label htmlFor="password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  value={confirmpassword}
                  onChange={(e)=>setConfirmPassword(e.target.value)}
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Confirm Password"
                />
              </div>
            </div>

            

            <div>
              <button
              onClick={()=>signUp()}
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-400 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {/* <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> */}
                </span>
                Sign up
              </button>
              
              </div>
              

              {spin ? <>
                <div className="flex">
                  <Oval
  // ariaLabel="loading-indicator"
  ariaLabel="loading"
  height={40}
  width={40}
  strokeWidth={5}
  color="blue"
  secondaryColor="white"
/>
</div>
                
                </> : <></>}
                
              
            <div>
              <div class="flex text-sm ">
  <div>
    <div class="form-control">
                      <label class="cursor-pointer label">
                        
                        <input type="checkbox"  class="checkbox checkbox-accent" /> <span class="label-text ml-2">Please contact me via email </span>
                      </label>
                    </div>
    
    
  </div>
</div>
              <span className="text-center text-blue-800 text-sm">
              By clicking Sign Up, I agree that I have read and accepted
                 <a href='https://docsun.health/privacy-policy/' className='text-blue-900 font-bold'>  the
                  Terms of Use and Privacy Policy.</a>
                  
                    
              </span>
              
              {/* <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Login
              </button> */}
              <Link to="/" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Login</Link>
              
            </div>
            
          {/* </form> */}
        </div>
      </div>

      </div>
      
  </div>
  
</div>
    </>);
}
 
export default Register;