import React from 'react';

const DailyStats = (props) => {
    return (
        <>
            Daily {props.datechage}
        </>
      );
}
 
export default DailyStats;