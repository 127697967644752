import React from 'react';
// import { Chart } from "react-google-charts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Example from '../CustomChart';
import V2 from '../../module1/TabBar-Wellness@2x.png'
import Health from '../../module1/TabBar-Healthiness@2x.png'
import WellnessIcon from '../../module1/TabBar-Wellness_1@2x.png'
import { Link } from 'react-router-dom';
import DashPie from '../charts/DashPie';
import MyApp from '../Calender';
import Normal from '../../module1/WebAppModule1_Screen5Green.png'
import BodyMassIcon from '../module3/Body Mass Index (BMI)@2x.png'
import ExerciseIcon from '../module3/Exercise@2x.png'
import WaterIcon from '../module3/Water@2x.png'
import FoodIcon from '../module3/Food@2x.png'
import SleepIcon from '../module3/Sleep@2x.png'
import MenstralIcon from '../module3/Menstrual Cycle@2x.png'
import Gauge from '../charts/Gauge';
import BackIcon from '../../module1/SignInUserToggleButton.png'
import ScanButtonIcon from '../../module1/SignInUserScanButton.png'
// import BackIcon from '../module1/SignInUserToggleButton.png'



const BMI = () => {
  return (

    <>
    
    <div className="flex  md:flex-row flex-col">
      {/* <Example /> */}
      <div class="flex flex-row md:flex-col mr-1 md:w-60 w-auto justify-between border-r-2 ">

{/* 1 */}
<div className="flex flex-row md:flex-col mr-1 md:w-60 w-auto">
    {/* menus */}
    <div className="h-16 md:mx-0 mx-2">
    <div className="my-5 flex">

        <div className="ml-4">
            <img src={V2} className="w-7" />
        </div>
        <div className="ml-2">
           <Link to='/scan'> Vital Signs</Link>

        </div>
        
        
        
        </div>  
</div>
<div className=" h-16 md:mx-0 mx-2">
    <div className="my-5 flex">
        <div className="ml-4">
            <img src={Health} className="w-7" />
        </div>
        
        <div className="ml-2">
           <Link to='/healthness'> Healthness</Link>

        </div>
    </div>

</div>
<Link to='/wellness'>
<div className=" h-16 md:mx-0 mx-2 border-y-2 bg-blue-200 border-r-4 border-r-blue-500">
    <div className="my-5 flex">
        
        <div className="ml-4">
            <img src={WellnessIcon} className="w-7" />
        </div>
        
        <span className="ml-2 ">
            Wellness 

            </span>
            
    </div>
   

</div>
</Link>
    
</div>
<div className="md:visible invisible">
    
    <div className="flex flex-col">
        <div>
            <img src={ScanButtonIcon} />
        </div>
        <div className=" bg-gray-400 mr-7 h-20">
            <span className=" my-8 mx-8 pt-3 mt-8"> Version: 2.3.1 </span>
        </div>
        <div className="h-20">
            <img src={BackIcon} className=" mx-12 my-5" />
        </div>


    </div>

</div>




</div>

            
      
        <div className="flex flex-col w-full">
            <div className='flex flex-row justify-between bg-gradient-to-b from-blue-500 to-white py-3'>
                <div className='flex flex-row'>
                    <div>
                        <Link to='/wellness'>
                        <img src={BackIcon} className='mr-6 h-10' />

                        </Link>
                     
                    </div>
                    <div className="text-extrabold text-2xl">
                    BMI Calculator
                    </div>

                
                </div>
                <div className="mr-8 ">
                    <Link to='/bmistatistics'>
                    <button className='btn btn-success h-9 text-white text-2xl text-extrabold bg-green-500 hover:bg-green-700 w-64'> Statistics</button>
                    </Link>
                    

                </div>

            </div>
            <div className=' text-extrabold text-2xl'>
            Calculate BMI
            </div>
          <div className="flex bg-white flex-col md:flex-row w-full">
              {/* Here */}
              
            
                <div className="flex md:w-1/2 w-full">
                    <div className="border-2 rounded-lg w-full flex flex-col bg-gradient-to-b from-blue-500 to-white">
                    
                    <div className="border-t-2 border-white">
                        <Gauge />
                        
                    </div>
                    <div className="flex flex-row border-2 rounded-lg">
                        <div className="mt-3">
                            Weight
                        </div>
                        <div className="mt-3">
                            <input className='rounded-lg w-20 ml-2' />
                        </div>
                        <div>
                            <div className='form-control'>
                                <div className='label cursor-pointer'>
                                    <span className='label-text'>
                                        lb
                                    </span>
                                    <input type='checkbox' className='toggle toggle-accent toggle-lg' />
                                    <span className='label-text'>
                                        Kgs
                                    </span>


                                </div>

                            </div>
                        </div>
                        <div className="mt-3 ml-6">
                            Height
                        </div>
                        <div className="mt-3">
                            <input className='rounded-lg w-20 ml-2' />
                        </div>
                        <div>
                            <div className='form-control'>
                                <div className='label cursor-pointer'>
                                    <span className='label-text'>
                                        cm
                                    </span>
                                    <input type='checkbox' className='toggle toggle-accent toggle-lg' />
                                    <span className='label-text'>
                                        ft
                                    </span>


                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="text-black text-center text-extrabold text-3xl justify-center flex flex-row mx-3">
                    <div>
                        <button className='btn bg-green-500 hover:bg-green-500 text-extrabold text-2xl text-white'>
                            Calculate
                        </button>
                    </div>
                    <div>
                    <button className='btn btn-success bg-indigo-900 hover:bg-indigo-900 text-extrabold text-2xl text-white'>
                            Save Results
                        </button>
                    </div>
                    
                        
                    </div>
                    <hr className="mx-9 w-full" />
                        {/* <div className="text-1xl text-bold mx-4">
                        <p>
                            The Health Score summarizes the health status of an individual into one number that measures the overall health and wellbeing of a person in real time.
                        </p>
                        <p>
                        The Health Score indicator is a scientifically calculated number from 1 (low) to 1000 (high) that moves up or down, depending on how your body, emotional wellbeing, or lifestyle data change.
                        </p>
                        </div> */}

                    

                    </div>
                </div>
                <div className="flex md:w-1/2 w-full flex-col ml-3">
                    <div className="text-extrabold text-2xl">
                        Result
                        <hr />
                    </div>
                    <div className='flex flex-col '>
                        
                        <div className="flex flex-row justify-between bg-slate-200 my-1 rounded-lg ">
                            {/* one row */}
                            <div className='ml-2'>
                            Below Normal Class II
                            </div>
                            <div className="pr-3">
                            {'<16'}
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-green-50 rounded-lg">
                            {/* one row */}
                            <div className='ml-2'>
                            Below Normal Class I
                            </div>
                            <div className="pr-3">
                            16.0-16.9
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-green-50 rounded-lg mt-1">
                            {/* one row */}
                            <div className='ml-2'>
                            Below Normal
                            </div>
                            <div className="pr-3">
                            17.0-18.4
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-green-500 rounded-lg mt-1">
                            {/* one row */}
                            <div className='ml-2'>
                            Normal
                            </div>
                            <div className="pr-3">
                            18.5-24.9
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-yellow-50 rounded-lg mt-1">
                            {/* one row */}
                            <div className='ml-2'>
                            Above Normal
                            </div>
                            <div className="pr-3">
                            25.0-29.9
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-yellow-50 rounded-lg mt-1">
                            {/* one row */}
                            <div className='ml-2'>
                            Above Normal class I
                            </div>
                            <div className="pr-3">
                            30.0-34.9
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-yellow-50 rounded-lg mt-1">
                            {/* one row */}
                            <div className='ml-2'>
                            Above Normal class II
                            </div>
                            <div className="pr-3">
                            35.0-39.9
                            </div>
                        </div>
                        <div className="flex flex-row justify-between bg-yellow-50 rounded-lg mt-1">
                            {/* one row */}
                            <div className='ml-2'>
                            Above Normal class III
                            </div>
                            <div className="pr-3">
                            {'≥40.0'}
                            </div>
                        </div>

                    </div>

                </div>
                
          
          </div>
          <div className="flex md:flex-row flex-col ">
              <div className='md:w-1/3 overflow-auto h-80 mx-2'>
              <div className='py-3 text-2xl text-bold font-black'>
                        History
                </div>
                <div className="flex flex-col w-full">
                    
                    <div className="rounded-lg md:mx-0  md:mt-1 mt-4 bg-blue-500 flex flex-row justify-between">
                    {/* one card */}
                        
                        <div className="flex flex-col ml-2">
                            <div className='flex flex-col'>
                            <span className="text-white text-extrabold text-4xl font-black">
                                16.3 
                            </span>
                            <span className='text-sm text-white'> Below Normal class II</span>
                            </div>
                            
                        </div>
                        <div className='flex flex-row text-white text-sm'>

                                <div className='flex flex-row'>
                                
                                    <div className='flex flex-col'>
                                        <div>
                                        December 16, 2021
                                        </div>
                                        <div className='mt-3 justify-end'>
                                       <span className='justify-end'>Morning</span> 
                                        </div>
                                    </div>
                                    <div className='mx-2 py-5'>
                                        {'>'}
                                    </div>
                                </div>
                                <div>

                                </div>
                        </div>
                    </div>
                    <div className="rounded-lg md:mx-0  md:mt-1 mt-4 bg-red-500 flex flex-row justify-between">
                    {/* one card */}
                        
                        <div className="flex flex-col ml-2">
                            <div className='flex flex-col'>
                            <span className="text-white text-extrabold text-4xl font-black">
                                16.9
                            </span>
                            <span className='text-sm text-white'> Below Normal class I</span>
                            </div>
                            
                        </div>
                        <div className='flex flex-row text-white text-sm'>

                                <div className='flex flex-row'>
                                
                                    <div className='flex flex-col'>
                                        <div>
                                        December 16, 2021
                                        </div>
                                        <div className='mt-3 justify-end'>
                                       <span className='justify-end'>Morning</span> 
                                        </div>
                                    </div>
                                    <div className='mx-2 py-5'>
                                        {'>'}
                                    </div>
                                </div>
                                <div>

                                </div>
                        </div>
                    </div>
                    <div className="rounded-lg md:mx-0  md:mt-1 mt-4 bg-green-500 flex flex-row justify-between">
                    {/* one card */}
                        
                        <div className="flex flex-col ml-2">
                            <div className='flex flex-col'>
                            <span className="text-white text-extrabold text-4xl font-black">
                                17.3
                            </span>
                            <span className='text-sm text-white'> Normal</span>
                            </div>
                            
                        </div>
                        <div className='flex flex-row text-white text-sm'>

                                <div className='flex flex-row'>
                                
                                    <div className='flex flex-col'>
                                        <div>
                                        December 16, 2021
                                        </div>
                                        <div className='mt-3 justify-end'>
                                       <span className='justify-end'>Morning</span> 
                                        </div>
                                    </div>
                                    <div className='mx-2 py-5'>
                                        {'>'}
                                    </div>
                                </div>
                                <div>

                                </div>
                        </div>
                    </div>
                    <div className="rounded-lg md:mx-0  md:mt-1 mt-4 bg-indigo-900 flex flex-row justify-between">
                    {/* one card */}
                        
                        <div className="flex flex-col ml-2">
                            <div className='flex flex-col'>
                            <span className="text-white text-extrabold text-4xl font-black">
                                23.9
                            </span>
                            <span className='text-sm text-white'> Normal</span>
                            </div>
                            
                        </div>
                        <div className='flex flex-row text-white text-sm'>

                                <div className='flex flex-row'>
                                
                                    <div className='flex flex-col'>
                                        <div>
                                        December 16, 2021
                                        </div>
                                        <div className='mt-3 justify-end'>
                                       <span className='justify-end'>Morning</span> 
                                        </div>
                                    </div>
                                    <div className='mx-2 py-5'>
                                        {'>'}
                                    </div>
                                </div>
                                <div>

                                </div>
                        </div>
                    </div>
                </div>
                
              </div>
              <div className='md:w-1/3'>
              <div className='py-3 text-2xl text-extrabold font-black'>
                        Calender
                    </div>
                  <MyApp />

              </div>
              <div className='md:w-1/3 flex flex-col'>
              <div className='py-3 text-2xl text-bold font-black text-white'>
                        History
                </div>
                  <div className="text-extrabold text-white text-3xl bg-yellow-500 py-5 rounded-lg text-center">
                  Above Normal Class I
                  </div>
                  <div className='py-3'>
                  63.50 kg | 170.2 cm | Female | 30 Years old

                  </div>
                  <div className='py-3 bg-white text-center border-2 rounded-lg'>
                  Normal weight for your height (200.2 cm) <br />
74.15 kg - 99.80 kg (+ 30.20 kg)

                  </div>
                  

              </div>
          </div>
        </div>
        



       

            
      
      
      </div>

      </>
      
      );
}
 
export default BMI;