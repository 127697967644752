import React,{useEffect, useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import RecordVideo from './RecordVideo';
import Spo2 from '../module1/preview/Screen2-VitalSign1.png'
import HeartRate from '../module1/preview/Screen2-VitalSign2.png'
import BsRate from '../module1/preview/Screen2-VitalSign3.png'
// import Temp from '../module1/preview/Screen2-VitalSign4.png'
import BPressure from '../module1/preview/Screen2-VitalSign5.png'
// import EyeColor from '../module1/preview/Screen2-VitalSign6.png'
import Breathing from '../module1/preview/Screen2-VitalSign7.png'
import Healthy from '../module1/WebAppModule1_Screen5Green.png'
import Caution from '../module1/WebAppModule1_Screen6Yellow.png'
import V2 from '../module1/TabBar-Wellness@2x.png'
import Health from '../module1/TabBar-Healthiness@2x.png'
import Wellness from '../module1/TabBar-Wellness_1@2x.png'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import Smile from '../module1/WebAppModule1_Screen8PopSmile.png'
import { AuthContext } from '../App';
// import 

const Scan = () => {
    // https://codesandbox.io/s/zhde4?file=/src/App.js
    const [healthstatus, setHealthStatus] = useState('initial')
    const [count, setCount]=useState(0)
//     useEffect(() => {
//   const interval = setInterval(() => {
//     // console.log('This will run every second!');
      
//       getVal('e')
//   }, 5000);
//   return () => clearInterval(interval);
//     }, []);
    
    const openDialog2 = () => {
        NotificationManager.warning('Please allow camera permission');
        
    }


    
   
    const reClick = () => {
        // alert('hello');
        // setCount(1)
        // document.getElementById("scan").click();
        // document.getElementById("scan").click();

        // NotificationManager.info('Loading....');
        

        // openDialog2()


        

        // setTimeout(function () {
        //     reClick();
        // }, 5000);
    }
    const getVal = (e) => {
        // alert(document.getElementById('diagnosis').innerHTML)
        // alert(e)
        // console.log(e)
        var currentval = document.getElementById('diagnosis').innerHTML
        
        if (currentval != '' && currentval != healthstatus) {
            setHealthStatus(currentval)
            
        }
        
        
        // var number=document.getElementById("diagnosis").value
        // alert(number)
        // console.log(number)
        // getVal
    }
    return (<>
        <div class="rounded-lg flex flex-col md:flex-row mx-0 bg-white shadow-2xl">
            <NotificationContainer/>
            <div class="flex flex-row md:flex-col mr-1 md:w-60 w-auto ">
                {/* 1 */}
                
                <div className="  h-16 md:mx-0 mx-2">
                    <div className="my-5 flex">

                        <div className="ml-4">
                            <img src={V2} className="w-7" />
                        </div>
                        <div className="ml-2">
                           <Link to='/scan'> Vital Signs</Link>

                        </div>
                        
                        
                        
                    </div>  
                </div>
                <div className=" bg-blue-200 h-16 md:mx-0 mx-2">
                    <div className="my-5 flex">
                        <div className="ml-4">
                            <img src={Health} className="w-7" />
                        </div>
                        
                        <div className="ml-2">
                           <Link to='/healthness'> Healthness</Link>

                        </div>
                    </div>

                </div>
                <div className=" h-16 md:mx-0 mx-2 border-y-2">
                    <div className="my-5 flex">
                        
                        <div className="ml-4">
                            <img src={Wellness} className="w-7" />
                        </div>
                        
                        <span className="ml-2">
                           <Link to='/wellness'> Wellness </Link>

                            </span>
                            
                    </div>
                   

                </div>
                
                
            </div>
            <div class="w-50">
                {/* 2 */}
                {/* {healthstatus} */}
                
               
                <br />
                <div>
                
                <div className=" w-full h-full grid grid-flow-row grid-cols-2">

                  <div className="flex flex-col m-4">
                      {/* Left part */}

                      
                  </div>
                  <div className="flex flex-row-reverse mt-4">
                            {/* right part */}
                            

                      <div className="flex flex-col">
                          
                      
                      

                      </div>
                      
                      
                  </div>

              </div>
  </div>
  <div className="flex m-3">
      <div>
          {/* <button id="scan" onClick={()=>reClick()} className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Start Scanning</button> */}
          {/* <button onClick={()=>reClick()}>Test Click</button> */}
    
        
      </div>
      <div className='ml-6'>
          <div>
              {/* <button id="stop" disabled className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>Stop Scanning</button> */}
          </div>
      </div>
  </div>
  {/* <span onClick={(e)=>getVal(e)}>
      <button  id="diagnosis" >Get val</button>
      
  </span> */}
  
  
   
        
            </div>
            <div class=" mx-8 rounded-lg border-red-200  w-80">
                {/* flex 3 */}
                <div className="text-blue-500 font-extrabold text-3xl text-center w-64">
                    Result
                </div>
                <div className=" min-h-80 rounded-lg bg-blue-400 border-white border-red-200">
                    {healthstatus == 'Healthy' ? <>
                        <img src={Healthy} alt='Healthy' className="ml-20 py-10" />
                        <div className="text-white font-extrabold text-center text-2xl">
                            You are good to go
                        </div>
                    
                    </> : <></>}
                    {healthstatus == 'initial' ? <>
                        {/* <img src={Healthy} alt='Healthy' className="ml-20 py-10" /> */}
                        <div className="text-white font-extrabold text-center text-2xl h-80 ">
                        <div className="justify-between py-20">   Welcome to Non-Contact
                            Health Monitoring System </div>
                        </div>
                    
                    </> : <></>}
                    {healthstatus == 'Loading' ? <>
                        {/* <img src={Healthy} alt='Healthy' className="ml-20 py-10" /> */}
                        <div className="text-white font-extrabold text-center text-2xl h-80 ">
                        <div className="justify-between py-20">   Welcome to Non-Contact
                            Health Monitoring System </div>
                        </div>
                    
                    </> : <></>}
                    {healthstatus == 'Caution' ? <>
                        <img src={Caution} alt='Caution' className="ml-20 py-10" />
                        <div className="text-white font-extrabold text-center text-2xl">
                            We identified some Abnormalities Please scan again
                        </div>
                    
                    </> : <> &nbsp;</>}

                </div>
                <div className='py-5'>
                    <Link to="/healthness" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Scan History</Link>

                    {/* <label for="my-modal" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Scan History</label> */}

                </div>
                
                
            </div>
        </div>

        <input type="checkbox" id="my-modal" class="modal-toggle" />
        <div class="modal">
            <div class="modal-box w-96">
                <img src={Smile} className="object-center ml-14" />
            <h3 class="font-bold text-2xl text-blue-500 text-extrabold text-center">
                Do you want to save and check your history
            </h3>
                <div className="flex flex-col">
                    <div className="my-5">
                        <Link to="/" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-300 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'> Login</Link>
                    </div>
                    <div>
                        <Link to="/register" className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-blue-400  bg-white hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border-2 border-blue-500'> Sign Up</Link>

                    </div>
                    <div className="my-5 flex flex-col">
                         <label for="my-modal" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-900 hover:text-white focus:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Scan History
                            
                           <br /> 2 free scan remaining
                        </label>

                    </div>
            </div>
            <div class="modal-action">
            {/* <label for="my-modal" class="btn">Yay!</label> */}
            </div>
        </div>
        </div>
    </>);
}
 
export default Scan;