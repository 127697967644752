import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { Link, useParams } from 'react-router-dom';
import url from '../url';

const Verify = () => {
    let { token } = useParams();
    // const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [errors, setErrors] = useState([])
    const [spin, setSpin] = useState(true)
    const [success, setSuccess] = useState(false)

    
    useEffect(() => {
        setSpin('grow')
        axios.put(url + 'auth/verify-account',{token})
            .then(res => {
            setSuccess(true)
            }).catch(e => {
            setError(e.message)
            setError(e.response.data.message)
            // setError(e.response.date.message)
            }).then(r => {
            setSpin(false)
        })
        
    },[])
    return (<>
        {spin ? <>
            
                  <Oval
  // ariaLabel="loading-indicator"
  ariaLabel="loading"
  height={40}
  width={40}
  strokeWidth={5}
  color="blue"
  secondaryColor="white"
/>
                
        </> : <></>}
        
        {error != '' ? <>
            
            <div className="flex flex-row md:px-80">
                <div className="border-2 border-blue-400 rounded-lg py-6 mt-20 px-3">
                    {error}
        

                </div>
            </div>
          
        </> : <></>}
        { success != '' ? <>
            
            <div className="flex flex-row md:px-80">
                <div className="border-2 border-blue-400 rounded-lg py-6 mt-20 px-3">
                    
                    Verification successful
                    <Link to="/" className="btn btn-accent mr-5">Go to login</Link>
                    
                    

                </div>
            </div>
        
        </> : <></>}
        
    
    </>);
}
 
export default Verify;