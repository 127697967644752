import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../App';

import Logo from '../module1/SignInUserLogo@2x.png'
import Bell from '../module1/SignInUserNotificationButton.png'

const Navbar = () => {
  const { auth, dispatchAuth } = useContext(AuthContext)
  
  return (
    <>
      <div className="flex flex-row bg-blue-400 mb-0  justify-between border-b-2 border-white">
        <div className="flex md:flex-row flex-col">
          <div>
        <Link to='/scan'>  <img src={Logo} alt="Logo" className=' w-96' /></Link>
          </div>
          
          <div className="ml-4">
            <input type="text" placeholder="Search" class="input input-bordered input-info w-full max-w-xs w-80" />
          </div>
        </div>
        {auth.logged_in ? <>
          <div className="flex justify-between mr-10">
          <div>
            <img src={Bell} alt="Logo" className="w-8 py-3" />
          </div> 
          <div>
            <div class="dropdown dropdown-hover">
            <label tabindex="0" className="my-2 btn bg-blue-400 border-0 hover:bg-blue-400">Profile <i class="fa-solid fa-caret-down"></i></label>
            <ul tabindex="0" class="p-2 shadow menu dropdown-content bg-base-100 rounded-box w-52">
              {/* <li><a>Item 1</a></li> */}
              <li> <Link to="/logout">Logout</Link></li>
            </ul>
          </div>
          </div>
        </div>
        
        </> : <>
            <div>
              {/* <Link to="/" className="btn btn-accent mr-5">Sign Up</Link> */}
              
              
            </div>
        
        </>}
        
      </div>
    </>
      


     );
}
 
export default Navbar;