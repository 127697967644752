import React, { useContext, useEffect, useState } from 'react';
// import { Chart } from "react-google-charts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Example from './CustomChart';
import V2 from '../module1/TabBar-Wellness@2x.png'
import Health from '../module1/TabBar-Healthiness@2x.png'
import Wellness from '../module1/TabBar-Wellness_1@2x.png'
import { Link, Navigate } from 'react-router-dom';
import DashPie from './charts/DashPie';
import MyApp from './Calender';
import Normal from '../module1/WebAppModule1_Screen5Green.png'
import { AuthContext } from '../App';
import axios from 'axios';
import url from './url';
import Spo2Icon from '../module1/icons/Icon01.png'
import LungIcon from '../module1/icons/Icon02.png'
import HeartIcon from '../module1/icons/Icon03.png'
import TempIcon from '../module1/icons/Icon04.png'
import EyeIcon from '../module1/icons/Icon05.png'
import Spo2Icon2 from '../components/module2/SPO2 Levels@2x.png'
import BloodSugarIcon2 from '../components/module2/Blood Sugar @2x.png'
import BloodPressureIcon2 from '../components/module2/Blood Pressure@2x.png'
import BreathingIcon2 from '../components/module2/Breathing Rate@2x.png'
import HeartRateIcon2 from '../components/module2/Heart Rate @2x.png'
import TempIcon2 from '../components/module2/Temperature@2x.png'
import EyeIcon2 from '../components/module2/Eye Redness@2x.png'
import EyeIconLike2 from '../components/module2/Eye Redness_1@2x.png'
import ScanCountIcon2 from '../components/module2/Scan Count@2x.png'
var user2=''

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];
const Healthness = () => {
  const { auth, dispatchAuth } = useContext(AuthContext)
  const [redirect, setRedirect] = useState(false)
  const [user, setUser] = useState({})
  const [latest6, setLatest6] = useState([])
  const [error, setError] = useState('')
  const [empty, setEmpty] = useState(false)
  const [access, setAccess] = useState('')
  const [diagnosis, setDiagnosis] = useState({})
  
  var user2 = JSON.parse(auth.user)
    console.log(user2.accessToken)
  //   setAccess(user2.accessToken)
  // console.log('auth')
  
  const mytoken=user2.accessToken
  useEffect(() => {
    // console.log('I am here')

  setUp()
  fetchDiagnosis()
  }, [])
  const setUp = () => {

  if (!auth.logged_in) {
    setRedirect(true)
    return;
  }
    // console.log(auth.user)
    // console.log('auth')
    var user2 = JSON.parse(auth.user)
    console.log(user2.accessToken)
    setAccess(user2.accessToken)
    console.log('auth')
  
    setUser(user2)
    setLatest6(user2.latest_diagnoses)

    

  
  }
  
  const renderlatest = latest6.map(latest => (
    <div className='flex flex-row justify-between my-2 border-b-2'>
      <div className='flex flex-row space-x-3'>
        {/* First colomn */}
        <div>
        <img src={Normal} className="h-10 w-10" />
        </div>
        <div className='font-bold'>
        Normal

        </div>

      </div>
      <div className='flex flex-row'>
        {/* second column */}
        <div className='flex flex-col text-gray-400'>
          <div>
            Today,
            
          </div>
          <div>
            10:48 am

          </div>

          
        </div>
        <div className='my-2 text-gray-400'>
          {'>'}
        </div>

      </div>

      {/* <div className=" my-3">
                <div className="flex flex-row">  <img src={Normal} className="h-10 w-10" /> <b>Normal</b>  <span className="text-right">Tuesday</span> </div>
                </div> */}
    </div>
  ))

  const fetchDiagnosis = () => {
    // alert('start')
    console.log('hello world')
    console.log(user2)
    var token = user.accessToken
    console.log('access')
    console.log(access)
        const config = {
            headers: {
                Authorization: `Bearer ${mytoken}`,
                language: 'en'
            }
        };


  axios.get(url + 'wellness/user/diagnosis', config)
    .then(res => {
              // alert('hello')
                console.log('res.data')
                console.log(res.data)
                // setSuccess(res.data.message)

                let array = res.data.result;
                if (array === undefined || array.length == 0) {
                    setEmpty(true)

                } else {
                    const lastItem = array[0]
                    
                  setDiagnosis(lastItem)
                  
                  console.log('diagnosis')
                  console.log(diagnosis.respiration_rate_range)
                    


                }


            }).catch(e => {
                // setError('We didn\'t find your email in the system Kindly create an account')
                // console.log(e)
                setError(e.message)
                setError('e.message')



            }).then(r => {
                // setSpin('false')
                // setMessage('')
                // setShow(false)
              console.log('hello')

            })
  }
  // })
  
  return (

    <div className="">
      {redirect ? <>
        <Navigate to="/" />
        
        
    
    </> : <></>}
      <div className="flex  md:flex-row flex-col">
        <div class="flex flex-row md:flex-col mr-1 md:w-60 w-auto ">
                {/* 1 */}
                
                <div className="  h-16 md:mx-0 mx-2">
                    <div className="my-5 flex">

                        <div className="ml-4">
                            <img src={V2} className="w-7" />
                        </div>
                        <div className="ml-2">
                           <Link to='/scan'> Vital Signs</Link>

                        </div>
                        
                        
                        
                    </div>  
                </div>
                <div className=" bg-blue-200 h-16 md:mx-0 mx-2">
                    <div className="my-5 flex">
                        <div className="ml-4">
                            <img src={Health} className="w-7" />
                        </div>
                        
                        <div className="ml-2">
                           <Link to='/healthness'> Healthness</Link>

                        </div>
                    </div>

                </div>
                <div className=" h-16 md:mx-0 mx-2 border-y-2">
                    <div className="my-5 flex">
                        
                        <div className="ml-4">
                            <img src={Wellness} className="w-7" />
                        </div>
                        
                        <span className="ml-2">
                           <Link to='/wellness'> Wellness </Link>

                            </span>
                            
                    </div>
                   

                </div>
                
                
        </div>
        
        <div className='flex flex-col'>
          <div className="flex flex-row bg-blue-400 justify-between">
            
            <div className="flex flex-row">
              
              <div><img src={user.profile_photo} alt='Profile photo' className="h-16 rounded-full ml-4" /></div>
              
              <div className="my-5 ml-2 font-3xl"> <span className="font-extrabold font-3xl">{user.name}, </span>You are Healthy and Ready to go</div>
            </div>
            <div>
                <Link to="/scan" className="btn btn-accent mr-5">New Scan</Link>
            </div>
        </div>
          <div className="flex  md:flex-row flex-col my-3">
            {/* Cards */}
            <div className=" w-full md:w-3/4 flex flex-row flex-wrap">
              <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={Spo2Icon2} className="h-9" />
                              
                            </div>
                            <div className='font-bold py-2'>
                            Sp0 2 Levels

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            <div className='text-center font-bold flex flex-col '>
                            
                            <div>
                            { diagnosis.respiration_rate_range}
                            </div>
                            <div>
                              &nbsp;

                            </div>

                              
                            </div>
                            <div>
                            <Example />

                            </div>
                            
                          </div>
                </div>
            </div>
              <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={BloodSugarIcon2} className="h-9" />
                              
                            </div>
                            <div className='font-bold py-1'>
                            Blood Sugar

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            <div className=' text-center font-bold flex flex-col '>
                            
                            <div>
                            {diagnosis.blood_sugar_range}
                              
                            </div>
                            <div>
                            mmol/L

                            </div>
                              
                            </div>
                            <div>
                            <Example />

                            </div>
                            
                          </div>
                </div>
            </div>
              <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={BloodPressureIcon2} className="h-8" />
                              
                            </div>
                            <div className='font-bold py-1'>
                            Blood Pressure

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            <div className=' text-center font-bold flex flex-col '>
                            
                            <div>
                            {diagnosis.blood_pressure_range}
                            
                              
                            </div>
                            <div>
                            mmHg
                            </div>
                              
                            </div>
                            <div>
                            <Example />

                            </div>
                            
                          </div>
                </div>
            </div>
              <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={BreathingIcon2} className="h-8" />
                              
                            </div>
                            <div className='font-bold py-1'>
                            Blood Pressure

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            <div className=' text-center font-bold flex flex-col '>
                            
                            <div>
                            {diagnosis.respiration_rate_range}
                            
                            
                              
                            </div>
                            <div>
                            RPM
                            </div>
                              
                            </div>
                            <div>
                            <Example />

                            </div>
                            
                          </div>
                </div>
            </div>
              <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={HeartRateIcon2} className="h-8" />
                              
                            </div>
                            <div className='font-bold py-1'>
                            Hearth Rate

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            <div className=' text-center font-bold flex flex-col '>
                            
                            <div>
                            {diagnosis.heart_rate_range}
                      
                            
                            
                              
                            </div>
                            <div>
                            BPM
                            </div>
                              
                            </div>
                            <div>
                            <Example />

                            </div>
                            
                          </div>
                </div>
            </div>
              <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={TempIcon2} className="h-8" />
                              
                            </div>
                            <div className='font-bold py-1'>
                            Temperature

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            <div className=' text-center font-bold flex flex-col '>
                            
                            <div>
                            
                            {diagnosis.temperature == '' ? <>
                        N/A 
                      
                      </> : <>
                          {diagnosis.temperature} 
                          
                      </>}
                      
                            
                            
                              
                            </div>
                            <div>
                            {diagnosis.temperature == '' ? <>
                        {/* N/A  <br /> */}
                      
                      </> : <>
                           {diagnosis.temperature_unit}
                          
                      </>}
                            </div>
                              
                            </div>
                            <div>
                            <Example />

                            </div>
                            
                          </div>
                </div>
            </div>
            
            <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={EyeIcon2} className=" h-7" />
                              
                            </div>
                            <div className='font-bold py-1'>
                            Eye Redness

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            <div className=' text-center font-bold flex flex-col  py-14'>
                            
                              <img src={EyeIconLike2} className=" h-20" />
                            
                              
                            </div>
                            
                            
                          </div>
                </div>
            </div>
            
            
            <div className=" h-80 ml-1">
                    <div class=" p-6 max-w-sm rounded-lg border border-gray-200 shadow-md flex flex-col">
                          <div className='flex flex-row space-x-3'>
                            <div>
                              {/* Icon */}
                              <img src={ScanCountIcon2} className="h-8" />
                              
                            </div>
                            <div className='font-bold py-1'>
                            Scan Count

                            </div>

                          </div>
                          <div className='flex flex-col'>
                            {/* second column */}
                            
                            <div>
                            {/* <Example /> */}
                            {/* <div class="radial-progress" style={{"--value":70, "--size":"10rem", "--thickness": "2px" }}>80%</div> */}
                            
                            <div className='ml-5 py-5'>
                            <div class="radial-progress text-2xl text-green-500 font-bold" style={{"--value":70, "--size":"10rem"}}> <span className='text-black'>126</span></div>
                            </div>

                            </div>
                            
                          </div>
                </div>
            </div>
            
            </div>
            <div className="border-2  rounded-lg">
              {/* Chart */}
              {/* <span>Hello</span> */}
              {/* Hello */}
              <div className="md:w-96 w-full text-center text-3xl text-extrabold bg-blue-400  rounded-lg text-white" >
                Vital Signs <br /> Weekly Chart
              </div>
            <DashPie vitals={diagnosis} />
            </div>
          </div>
          <div className="flex md:flex-row flex-col">
            {/* Row 2 */}
            
            <div>
              <span className='ml-2 text-extrabold text-3xl'>Activity</span>
              {/* ACtivity part */}
              <div class="card w-96 h-72 bg-base-100 shadow-xl border-4 overflow-auto">
              <div class="card-body">
                {/* <h2 class="card-title">Card title!</h2>
                <p>If a dog chews shoes whose shoes does he choose?</p>
                <div class="justify-end card-actions">
                  <button class="btn btn-primary">Buy Now</button>
                </div> */}
                <hr />
                <div className='flex flex-col'>
                   {renderlatest}
                    
                </div>
                  
                
                
                </div>
              </div>{/* End of activity */}
            </div>
            <div className="ml-2">
              {/* Calender */}
              <span className='ml-2 text-extrabold text-3xl'>Calender</span>
              <div className="flex flex-col md:flex-row border-2 rounded-lg p-1" >
                <div>
                  <MyApp className="border-2 rounded-lg" />
                </div>
                <div className="flex flex-row flex-wrap py-0 my-0">
                  {/* outer row */}
                    <div className="h-16 w-60 mx-3 flex flex-row rounded-lg">
                      {/* one pill */}
                      <div className="w-1/4 bg-red-500 rounded-l-lg">
                        <img src={Spo2Icon} className="bg-transparent h-10 object-center my-2 ml-2" />
                      </div>
                      <div className="w-3/4 bg-orange-500 rounded-r-lg">
                    <span className='ml-2'>  Sp02 Levels </span><hr />
                     <span className="ml-2"> { diagnosis.respiration_rate_range}</span>
                      

                      </div>
                    </div>
                    <div className="h-16 w-60 mx-3 flex flex-row">
                      {/* one pill */}
                      <div className="w-1/4 bg-blue-600 rounded-l-lg">
                        <img src={HeartIcon} className="bg-transparent h-10 object-center my-2 ml-2" />
                      </div>
                      <div className="w-3/4 bg-blue-500 rounded-r-lg">
                     <span className="ml-2"> Heart Rate </span> <hr />
                        <span className='ml-2' >{diagnosis.heart_rate_range}  BPM
                        </span>
                      

                      </div>
                    </div>
                    <div className="h-16 w-60 mx-3 flex flex-row">
                      {/* one pill */}
                      <div className="w-1/4 bg-orange-600 rounded-l-lg">
                        <img src={Spo2Icon} className="bg-transparent h-10 object-center my-2 mx-2" />
                      </div>
                      <div className="w-3/4 bg-orange-300 rounded-r-lg">
                      <span className="ml-2">Blood Sugar </span><hr />
                     <span className="ml-2"> {diagnosis.blood_sugar_range}</span>
                      

                      </div>
                    </div>
                    <div className="h-16 w-60 mx-3 flex flex-row">
                      {/* one pill */}
                      <div className="w-1/4 bg-blue-900 rounded-l-lg">
                        <img src={TempIcon} className="bg-transparent h-10 object-center my-2 ml-2" />
                      </div>
                      <div className="w-3/4 bg-blue-200 rounded-r-lg">
                     <span className='ml-2'> Temperature </span><hr />
                    <span className='ml-2'>  {diagnosis.temperature == '' ? <>
                        N/A  <br />
                      
                      </> : <>
                          {diagnosis.temperature} {diagnosis.temperature_unit}
                          
                      </>}
                        </span>
                      

                      </div>
                    </div>
                    <div className="h-16 w-60 mx-3 flex flex-row">
                      {/* one pill */}
                      <div className="w-1/4 bg-pink-700 rounded-l-lg">
                        <img src={TempIcon} className="bg-transparent h-10 object-center my-2 ml-2" />
                      </div>
                      <div className="w-3/4 bg-pink-400 rounded-r-lg">
                     <span className='ml-2'> Blood Pressure</span> <hr />
                     <span className='ml-2'> {diagnosis.blood_pressure_range}
                        mmHg
                        </span>
                      
                      

                      </div>
                    </div>
                    <div className=" h-16 w-60 mx-3 flex flex-row">
                      {/* one pill */}
                      <div className="w-1/4 bg-pink-400 rounded-l-lg">
                        <img src={LungIcon} className="bg-transparent h-10 object-center my-2" />
                      </div>
                      <div className="w-3/4 bg-pink-200 rounded-r-lg">
                     <span className='ml-2'> Breathing Rate</span> <hr />
                     <span className='ml-2'></span> {diagnosis.respiration_rate_range}
                      RPM
                      {/* </div> */}
                      
                      

                      </div>
                    </div>
                   

                </div>
                
                
                
              </div>
              
            </div>

          </div>
        </div>
        

      </div>

      
    
    
      </div>
      
      );
}
 
export default Healthness;