import React from 'react';
// import { Chart } from "react-google-charts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Example from '../CustomChart';
import V2 from '../../module1/TabBar-Wellness@2x.png'
import Health from '../../module1/TabBar-Healthiness@2x.png'
import WellnessIcon from '../../module1/TabBar-Wellness_1@2x.png'
import { Link } from 'react-router-dom';
import DashPie from '../charts/DashPie';
import MyApp from '../Calender';
import Normal from '../../module1/WebAppModule1_Screen5Green.png'
import BodyMassIcon from '../module3/Body Mass Index (BMI)@2x.png'
import ExerciseIcon from '../module3/Exercise@2x.png'
import WaterIcon from '../module3/Water@2x.png'
import FoodIcon from '../module3/Food@2x.png'
import SleepIcon from '../module3/Sleep@2x.png'
import MenstralIcon from '../module3/Menstrual Cycle@2x.png'
import Gauge from '../charts/Gauge';
import BackIcon from '../../module1/SignInUserToggleButton.png'
import ScanButtonIcon from '../../module1/SignInUserScanButton.png'
// import BackIcon from '../module1/SignInUserToggleButton.png'
import PlayIcon from '../module3/Asset 79@2x.png'
import AlarmIcon from '../module3/TimerClock@2x.png'
import FlagIcon from '../module3/Asset 77@2x.png'
import StopIcon from '../module3/StopTimer@2x.png'
import PauseIcon from '../module3/Pause Timer@2x.png'
import GymIcon from '../module3/gymgym.jpeg'



const Execise = () => {
  return (

    <>
    
    <div className="flex  md:flex-row flex-col">
      {/* <Example /> */}
      <div class="flex flex-row md:flex-col mr-1 md:w-60 w-auto justify-between border-r-2 ">

{/* 1 */}
<div className="flex flex-row md:flex-col mr-1 md:w-60 w-auto">
    {/* menus */}
    <div className="h-16 md:mx-0 mx-2">
    <div className="my-5 flex">

        <div className="ml-4">
            <img src={V2} className="w-7" />
        </div>
        <div className="ml-2">
           <Link to='/scan'> Vital Signs</Link>

        </div>
        
        
        
        </div>  
</div>
<div className=" h-16 md:mx-0 mx-2">
    <div className="my-5 flex">
        <div className="ml-4">
            <img src={Health} className="w-7" />
        </div>
        
        <div className="ml-2">
           <Link to='/healthness'> Healthness</Link>

        </div>
    </div>

</div>
<Link to='/wellness'>
<div className=" h-16 md:mx-0 mx-2 border-y-2 bg-blue-200 border-r-4 border-r-blue-500">
    <div className="my-5 flex">
        
        <div className="ml-4">
            <img src={WellnessIcon} className="w-7" />
        </div>
        
        <span className="ml-2 ">
            Wellness 

            </span>
            
    </div>
   

</div>
</Link>
    
</div>
<div className="md:visible invisible">
    
    <div className="flex flex-col">
        <div>
            <img src={ScanButtonIcon} />
        </div>
        <div className=" bg-gray-400 mr-7 h-20">
            <span className=" my-8 mx-8 pt-3 mt-8"> Version: 2.3.1 </span>
        </div>
        <div className="h-20">
            <img src={BackIcon} className=" mx-12 my-5" />
        </div>


    </div>

</div>




</div>

            
      
        <div className="flex flex-col w-full">
            <div className='flex flex-row justify-between bg-gradient-to-b from-blue-500 to-white py-3'>
                <div className='flex flex-row'>
                    <div>
                        <Link to='/wellness'>
                        <img src={BackIcon} className='mr-6 h-10' />

                        </Link>
                     
                    </div>
                    <div className="text-extrabold text-2xl">
                    Exercise Detail
                    </div>

                
                </div>
                <div className="mr-8 ">
                    <Link to='/wellness'>
                    <button className='btn btn-success h-9 text-white text-2xl text-extrabold bg-green-500 hover:bg-green-700 w-64'> Statistics</button>
                    </Link>
                    

                </div>

            </div>
            
          
          <div className="flex md:flex-row flex-col ">
              <div className='md:w-1/3 overflow-auto h-80 mx-2'>
              <div className='py-3 text-2xl text-bold font-black'>
              Calender
                </div>
                
                <MyApp />
              </div>
              <div className='md:w-1/3'>
                <div className='py-3 text-2xl text-extrabold font-black'>
                        Today's Session
                </div>
                <div className='flex flex-row border-2 rounded-lg px-5 py-20'>
                    <div className="font-black text-extrabold text-2xl flex flex-col w-1/2">
                        <div>
                            My Plan
                        </div>
                        <div>
                            For Today
                        </div>
                        <div className='text-sm text-gray-300'>
                        3/6 Complete
                        </div>
                    </div>
                    <div className="w-1/2">
                    <div className="radial-progress text-blue-500" style={{ "--value": 70 }}>
                        55%
                    </div>
                    </div>
                </div>
                 

              </div>
              <div className='md:w-1/3 flex flex-col mx-2'>
              <div className='py-3 text-2xl text-bold font-black'>
                        Time
                </div>
                <div className=' border-2 rounded-lg'>
                <div className='flex flex-row'>
                    <div className='w-1/2 bg-blue-500' >
                    <img src={AlarmIcon} className=" h-10 mx-16 rounded-full" />
                        
                    </div>
                    <div className='w-1/2 bg-blue-900'>
                        <img src={PlayIcon} className="h-10 mx-16" />

                    </div>

                </div>
                <div className='flex flex-row px-20'>
                    <div className='w-1/3 text-4xl font-black text-extrabold text-blue-500'>
                        00:
                    </div>
                    <div className='w-1/3 text-4xl font-black text-extrabold text-blue-500'>
                        00:
                    </div>
                    <div className='w-1/3 text-4xl font-black text-extrabold text-blue-500'>
                        00
                    </div>

                </div>
                <div className='flex flex-row'>
                Current timing

                </div>
                <div className='flex flex-col'>
                    <div className='flex flex-row px-10 border-y-2 my-1 '>
                        <div className='w-1/3'>
                            <img src={FlagIcon} className="h-6" />

                        </div>
                        <div className='w-1/3'>
                        + 00:01:08

                        </div>
                        <div className='w-1/3'>
                        00:07:83

                        </div>

                    </div>
                    <div className='flex flex-row px-10 border-y-2 my-1'>
                        <div className='w-1/3'>
                            <img src={FlagIcon} className="h-6" />

                        </div>
                        <div className='w-1/3'>
                        + 00:01:08

                        </div>
                        <div className='w-1/3'>
                        00:07:83

                        </div>

                    </div>
                    <div className='flex flex-row px-10 border-y-2 my-1'>
                        <div className='w-1/3'>
                            <img src={FlagIcon} className="h-6" />

                        </div>
                        <div className='w-1/3'>
                        + 00:01:08

                        </div>
                        <div className='w-1/3'>
                        00:07:83

                        </div>

                    </div>

                </div>
                <div className='flex flex-row'>
                    <div className='w-1/2'>
                        <button className='w-full bg-green-400'> <img src={PauseIcon} className="h-8 px-16 py-1"></img></button>

                    </div>
                    <div className='w-1/2'>
                    <button className='w-full bg-red-600'><img src={StopIcon} className="h-8 px-16 py-1"></img></button>

                    </div>
                </div>


                    
                </div>
                
                 

              </div>
              

          </div>
          <div className='flex flex-row mt-3'>
              <div className="w-1/3 flex flex-col">
                  <div className='text-black font-black text-2xl my-2'>
                  Today's Session
                  </div>
              

                <div>
                {/* <iframe class="w-full aspect-video ..." src="https://www.youtube.com/watch?v=eIEyDhCUZK0"></iframe> */}
                <iframe class="w-full aspect-video h-96" src="https://www.youtube.com/embed/eIEyDhCUZK0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                  
              </div>
              <div className='w-2/3 flex flex-col'>
                  <div className='text-black font-black text-2xl my-2'>
                    {'->'}
                  </div>
                  <div className='flex flex-col'>
                     
                      <div className='flex flex-row mx-2'>
                          {/* Row 1 */}
                          <div>
                              {/* image 1 */}
                              
                              <img src={GymIcon} alt="Hel" className='object-fill h-44 w-96 mx-1' /> 
                              
                          </div>
                          <div>
                              {/* image 2 */}
                              <img src={GymIcon} alt="Hel" className='object-fill h-44 w-96' />
                              
                          </div>
                          <div>

                          </div>
                      </div>
                      <div className='flex flex-row mx-2 my-2'>
                          {/* Row 2 */}
                          <div>
                              {/* image 1 */}
                              
                              <img src={GymIcon} alt="Hel" className='object-fill h-44 w-96 mx-1' /> 
                              
                          </div>
                          <div>
                              {/* image 2 */}
                              <img src={GymIcon} alt="Hel" className='object-fill h-44 w-96' />
                              
                          </div>
                          <div>

                          </div>
                      </div>

                  </div>

              </div>

          </div>
        </div>
        
        



       

            
      
      
      </div>

      </>
      
      );
}
 
export default Execise;